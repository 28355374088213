<template>
    <div class="city-finder">
        <div class="row label">
            <div class="col">Target</div>
        </div>
        <div class="row">
            <div class="col justify-content-around">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="city-finder-radio"
                        :id="`coords-${id}`"
                        :value="CitySearchOptionIdentifier.COORDS"
                        v-model="citySearchOption"
                        :checked="searchByCoords"
                        @change="resetState"
                    />
                    <label class="form-check-label" :for="`coords-${id}`">Coordinates</label>
                </div>
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="city-finder-radio"
                        :id="`city-name-${id}`"
                        :value="CitySearchOptionIdentifier.CITY_NAME"
                        v-model="citySearchOption"
                        :checked="searchByCityName"
                        @change="resetState"
                    />
                    <label class="form-check-label" :for="`city-name-${id}`">City Name</label>
                </div>
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="city-finder-radio"
                        :id="`player-name-${id}`"
                        :value="CitySearchOptionIdentifier.PLAYER_NAME"
                        v-model="citySearchOption"
                        :checked="searchByPlayerName"
                        @change="resetState"
                    />
                    <label class="form-check-label" :for="`player-name-${id}`">Player Name</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col search-input-wrapper">
                <div v-if="searchByCoords" class="d-flex gap-2">
                    <input
                        class="form-control"
                        v-model="inputX"
                        @focus="setInputFocus(true)"
                        @blur="setInputFocus(false)"
                        @input="setDebounceFlag"
                    />
                    <input
                        class="form-control"
                        v-model="inputY"
                        @focus="setInputFocus(true)"
                        @blur="setInputFocus(false)"
                        @input="setDebounceFlag"
                    />
                </div>
                <input
                    v-else
                    class="form-control"
                    v-model="inputCityOrPlayer"
                    @focus="setInputFocus(true)"
                    @blur="setInputFocus(false)"
                    @input="setDebounceFlag"
                />
                <div v-if="showSearchResults" class="search-results-wrapper scrollable-wrapper">
                    <div v-if="!getMatchedCities().length" class="search-result">No cities found</div>
                    <div v-for="city in getMatchedCities()" :key="city._id" class="search-result" @click="emitSelectedCity(city)">
                        {{ getFormattedSearchResult(city) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CitySearchOptionIdentifier } from '@/utils/constants/map';

export default {
    name: 'CityFinder',
    props: {
        id: {
            type: String,
            default: '',
        },
        allCities: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            CitySearchOptionIdentifier,

            inputDebounceFlag: false,
            inputDebounceAmount: 1000,
            inputDebounceTimeoutId: null,

            citySearchOption: CitySearchOptionIdentifier.COORDS,

            inputX: '',
            inputY: '',
            inputCityOrPlayer: '',

            inputFocused: false,
        };
    },
    emits: ['city-selected'],
    computed: {
        searchByCoords() {
            return this.citySearchOption === CitySearchOptionIdentifier.COORDS;
        },
        searchByCityName() {
            return this.citySearchOption === CitySearchOptionIdentifier.CITY_NAME;
        },
        searchByPlayerName() {
            return this.citySearchOption === CitySearchOptionIdentifier.PLAYER_NAME;
        },
        showSearchResults() {
            return this.inputFocused && !this.inputDebounceFlag && (this.inputX || this.inputY || this.inputCityOrPlayer);
        },
    },
    methods: {
        resetState() {
            this.inputDebounceFlag = false;
            this.inputX = '';
            this.inputY = '';
            this.inputCityOrPlayer = '';
        },

        setInputFocus(status) {
            setTimeout(() => {
                this.inputFocused = status;
            }, 10);
        },

        setDebounceFlag() {
            if (!this.inputDebounceFlag) {
                this.inputDebounceFlag = true;
                this.inputDebounceTimeoutId = setTimeout(() => {
                    this.inputDebounceFlag = false;
                }, this.inputDebounceAmount);
            } else {
                clearTimeout(this.inputDebounceTimeoutId);
                this.inputDebounceTimeoutId = setTimeout(() => {
                    this.inputDebounceFlag = false;
                }, this.inputDebounceAmount);
            }
        },

        getMatchedCitiesFromCoords() {
            const TrimmedX = this.inputX.trim();
            const TrimmedY = this.inputY.trim();

            const InputsEmptyOrNotInFocus = (TrimmedX === '' && TrimmedY === '') || !this.inputFocused;
            if (InputsEmptyOrNotInFocus) {
                return [];
            }

            return this.allCities
                .filter(city => (TrimmedX !== '' ? city.coords.x.toString() === TrimmedX : true))
                .filter(city => (TrimmedY !== '' ? city.coords.y.toString() === TrimmedY : true));
        },

        getMatchedCitiesFromCityName() {
            const InputEmptyOrNotInFocus = this.inputCityOrPlayer === '' || !this.inputFocused;
            if (InputEmptyOrNotInFocus) {
                return [];
            }

            return this.allCities.filter(
                city => !city.isBarbarian && city.name.toLowerCase().includes(this.inputCityOrPlayer.toLowerCase()),
            );
        },

        getMatchedCitiesFromPlayerName() {
            console.log('getMatchedCitiesFromPlayerName');
            const InputEmptyOrNotInFocus = this.inputCityOrPlayer === '' || !this.inputFocused;
            if (InputEmptyOrNotInFocus) {
                return [];
            }

            return this.allCities.filter(
                city => !city.isBarbarian && city.owner.toLowerCase().includes(this.inputCityOrPlayer.toLowerCase()),
            );
        },

        getMatchedCities() {
            switch (this.citySearchOption) {
                case CitySearchOptionIdentifier.COORDS:
                    return this.getMatchedCitiesFromCoords();
                case CitySearchOptionIdentifier.CITY_NAME:
                    return this.getMatchedCitiesFromCityName();
                case CitySearchOptionIdentifier.PLAYER_NAME:
                    return this.getMatchedCitiesFromPlayerName();
                default:
                    return [];
            }
        },

        getFormattedSearchResult(city) {
            return `${city.name} (${city.coords.x} | ${city.coords.y})${city.isBarbarian ? '' : ' - ' + city.owner}`;
        },

        emitSelectedCity(city) {
            this.$emit('city-selected', city);
			this.clearInputs();
        },

        clearInputs() {
            this.inputX = '';
            this.inputY = '';
            this.inputCityOrPlayer = '';
        },
    },
};
</script>

<style scoped>
.city-finder {
    display: flex;
    flex-direction: column;
    gap: 2px;
	width: 100%;
}

.row {
	margin: 0;
    flex-wrap: nowrap;
    gap: 2px;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.form-check-input,
.form-check-label {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.search-input-wrapper {
    position: relative;
}

.search-results-wrapper {
    position: absolute;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    left: 0;
    top: 2.625rem;
    z-index: 1;
    padding: 0.125rem;
    cursor: pointer;
}

.search-results-wrapper .search-result {
    padding: 0.375rem 1rem;
}

.search-results-wrapper .search-result:hover {
    background: brown;
}

.scrollable-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 10rem;
    overflow-y: overlay;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.scrollable-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.scrollable-wrapper::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.tooltip-transition-enter-active,
.tooltip-transition-leave-active {
    transition: filter 0.05s linear;
}

.tooltip-transition-enter-from,
.tooltip-transition-leave-to {
    filter: blur(3px);
}
</style>
