<template>
    <div class="city-info-wrapper" :style="`transform: translateX(-50%) scale(${1 / mapScaleFactor});`">
        <div class="city-name-wrapper">
            <div class="city-dot" :style="`background: ${CityColors[city.color]}`" />
            <span class="city-name" :style="`color: ${CityColors[city.color]}`">
                {{ city.name }}
            </span>
        </div>
        <span class="city-points">{{ city.points }}</span>
    </div>
</template>

<script>
import { CityColors } from '@/utils/constants/city';

export default {
    name: 'CityInfo',
    props: {
        city: {
            type: Object,
            default: () => {},
        },
        currentCityId: {
            type: String,
            default: '',
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            CityColors,
        };
    },
};
</script>

<style scoped>
.city-info-wrapper {
    position: absolute;
    top: -5%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: opacity 0.25s;
    pointer-events: none;
    width: max-content;
}

.city-name-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.city-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    box-shadow: 0 0 3px 1px black;
}

.city-name {
    position: relative;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}

.city-points {
    font-size: 0.6875rem;
    line-height: 0.6875rem;
    color: ghostwhite;
    text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}
</style>
