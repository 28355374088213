import {
    ActionTypesNotShownOnMap,
    MapArrowDirection,
    OutgoingActions,
} from '@/utils/constants/actions';
import MapArrow from '@/classes/mapArrows/MapArrow';
import MapArrowsPair from '@/classes/mapArrows/MapArrowsPair';
import { CITY_TILE_HEIGHT, CITY_TILE_WIDTH } from '@/utils/constants/map';

function isActionShownOnMap(action) {
    return !ActionTypesNotShownOnMap.includes(action);
}

function isSameCitiesPair(arrows_pair, action) {
    return (
        (arrows_pair.ownCity._id === action.initiator._id && arrows_pair.targetCity._id === action.target._id) ||
        (arrows_pair.ownCity._id === action.target._id && arrows_pair.targetCity._id === action.initiator._id)
    );
}

function sameArrowsPairExists(collection, action) {
    let isSamePair = false;

    collection.items.forEach(arrows_pair => {
        if (isSameCitiesPair(arrows_pair, action)) {
            isSamePair = true;
        }
    });

    return isSamePair;
}

function getArrowsPairByAction(collection, action) {
    let arrowsPair = false;

    collection.items.forEach(arrows_pair => {
        if (isSameCitiesPair(arrows_pair, action)) {
            arrowsPair = arrows_pair;
        }
    });

    return arrowsPair;
}

function isActionOutgoing(action) {
    return OutgoingActions.includes(action.actionType);
}

function isOutgoingArrowPresentInPair(arrows_pair) {
    let isArrowPresent = false;

    arrows_pair.items.forEach(arrow => {
        if (arrow.direction === MapArrowDirection.OUTGOING) {
            isArrowPresent = true;
        }
    });

    return isArrowPresent;
}

function getOutgoingArrowFromPair(arrows_pair) {
    let outgoingArrow = false;

    arrows_pair.items.forEach(arrow => {
        if (arrow.direction === MapArrowDirection.OUTGOING) {
            outgoingArrow = arrow;
        }
    });

    return outgoingArrow;
}

function isIncomingArrowPresentInPair(arrows_pair) {
    let isArrowPresent = false;

    arrows_pair.items.forEach(arrow => {
        if (arrow.direction === MapArrowDirection.INCOMING) {
            isArrowPresent = true;
        }
    });

    return isArrowPresent;
}

function getIncomingArrowFromPair(arrows_pair) {
    let incomingArrow = false;

    arrows_pair.items.forEach(arrow => {
        if (arrow.direction === MapArrowDirection.INCOMING) {
            incomingArrow = arrow;
        }
    });

    return incomingArrow;
}

function insertOutgoingActionIntoExistingArrowsPair(existing_arrows_pair, action) {
    if (isOutgoingArrowPresentInPair(existing_arrows_pair)) {
        const ExistingOutgoingArrow = getOutgoingArrowFromPair(existing_arrows_pair);
        ExistingOutgoingArrow.addItem(action);
    } else {
        const NewOutgoingArrow = new MapArrow([action], MapArrowDirection.OUTGOING);
        existing_arrows_pair.addItem(NewOutgoingArrow);
    }
}

function insertIncomingActionIntoExistingArrowsPair(existing_arrows_pair, action) {
    if (isIncomingArrowPresentInPair(existing_arrows_pair)) {
        const ExistingIncomingArrow = getIncomingArrowFromPair(existing_arrows_pair);
        ExistingIncomingArrow.addItem(action);
    } else {
        const NewIncomingArrow = new MapArrow([action], MapArrowDirection.INCOMING);
        existing_arrows_pair.addItem(NewIncomingArrow);
    }
}

function createNewOutgoingArrowsPair(collection, action) {
    const NewOutgoingArrow = new MapArrow([action], MapArrowDirection.OUTGOING);
    const NewArrowsPair = new MapArrowsPair([NewOutgoingArrow], action.initiator, action.target);
    collection.addItem(NewArrowsPair);
}

function createNewIncomingArrowsPair(collection, action) {
    const NewIncomingArrow = new MapArrow([action], MapArrowDirection.INCOMING);
    const NewArrowsPair = new MapArrowsPair([NewIncomingArrow], action.target, action.initiator);
    collection.addItem(NewArrowsPair);
}

export function addActionToMapArrows(collection, action) {
    if (!isActionShownOnMap(action)) {
        return;
    }

    if (sameArrowsPairExists(collection, action)) {
        const ExistingArrowsPair = getArrowsPairByAction(collection, action);
        if (isActionOutgoing(action)) {
            insertOutgoingActionIntoExistingArrowsPair(ExistingArrowsPair, action);
        } else {
            insertIncomingActionIntoExistingArrowsPair(ExistingArrowsPair, action);
        }
    } else {
        if (isActionOutgoing(action)) {
            createNewOutgoingArrowsPair(collection, action);
        } else {
            createNewIncomingArrowsPair(collection, action);
        }
    }
}

export function getCityCenterX(x) {
    return x * CITY_TILE_WIDTH + CITY_TILE_WIDTH / 2;
}

export function getCityCenterY(y) {
    return y * CITY_TILE_HEIGHT + CITY_TILE_HEIGHT / 2;
}

export function getDistanceBetweenCities(currentX, currentY, targetX, targetY) {
    return Math.sqrt((targetX - currentX) ** 2 + (targetY - currentY) ** 2);
}

export function getAngleBetweenCities(currentX, currentY, targetX, targetY) {
    return Math.atan2(targetY - currentY, targetX - currentX) * (180 / Math.PI);
}
