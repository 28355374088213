import Collection from '@/classes/generic/Collection';

export default class MapArrowsPair extends Collection {
    ownCity = null;
    targetCity = null;

    constructor(arrows, own_city, target_city) {
        super(arrows);
        this.ownCity = own_city;
        this.targetCity = target_city;
    }

    deleteItem(item) {
        super.deleteItem(item);
        if (this.items.size === 0) {
            this.selfDestruct()
        }
    }
}
