<template>
    <map name="city">
        <div
            v-if="mainBuilding?.level"
            :class="`city-building-image-wrapper main-building-${getBuildingAppearance(mainBuilding)}`"
        >
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ mainBuilding?.name }}</div>
                <div class="city-building-level">Level {{ mainBuilding?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/main_building_${getBuildingAppearance(mainBuilding)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/main_building_${getBuildingAppearance(mainBuilding)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/main_building_${getBuildingAppearance(mainBuilding)}_desktop.avif`)"
                    class="city-building-image"
                    :alt="mainBuilding?.name"
                />
            </picture>
        </div>
        <area
            v-if="mainBuilding?.level"
            shape="poly"
            coords="1002,293,1240,429,1348,412,1430,366,1367,266,1169,145,1040,204"
            alt="main-building"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#main-building-modal"
        />
        <div
            v-if="metalMiningCamp?.level"
            :class="`city-building-image-wrapper metal-mining-camp-${getBuildingAppearance(metalMiningCamp)}`"
        >
            <div class="city-building-info-wrapper metal">
                <div class="city-building-name">{{ metalMiningCamp?.name }}</div>
                <div class="city-building-level">Level {{ metalMiningCamp?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="
                        require(`@/assets/buildings/metal_mining_camp_${getBuildingAppearance(metalMiningCamp)}_mobile.avif`)
                    "
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="
                        require(`@/assets/buildings/metal_mining_camp_${getBuildingAppearance(metalMiningCamp)}_desktop.avif`)
                    "
                />
                <img
                    :src="require(`@/assets/buildings/metal_mining_camp_${getBuildingAppearance(metalMiningCamp)}_desktop.avif`)"
                    class="city-building-image"
                    :alt="metalMiningCamp?.name"
                />
            </picture>
        </div>
        <area
            v-if="metalMiningCamp?.level"
            shape="poly"
            coords="558,76,550,195,611,230,823,234,889,85,734,5"
            alt="metal-mine"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#resource-building-modal"
            @mouseover="setResourceModalData(metalMiningCamp?.name)"
        />
        <div
            v-if="oilExtractor?.level"
            :class="`city-building-image-wrapper oil-extractor-${getBuildingAppearance(oilExtractor)}`"
        >
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ oilExtractor?.name }}</div>
                <div class="city-building-level">Level {{ oilExtractor?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/oil_extractor_${getBuildingAppearance(oilExtractor)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/oil_extractor_${getBuildingAppearance(oilExtractor)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/oil_extractor_${getBuildingAppearance(oilExtractor)}_desktop.avif`)"
                    class="city-building-image"
                    :alt="oilExtractor?.name"
                />
            </picture>
        </div>
        <area
            v-if="oilExtractor?.level"
            shape="poly"
            coords="248,285,405,380,614,281,558,209,460,132"
            alt="oil-pumper"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#resource-building-modal"
            @mouseover="setResourceModalData(oilExtractor?.name)"
        />
        <div
            v-if="armoredFactory?.level"
            :class="`city-building-image-wrapper armored-factory-${getBuildingAppearance(armoredFactory)}`"
        >
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ armoredFactory?.name }}</div>
                <div class="city-building-level">Level {{ armoredFactory?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/armored_factory_${getBuildingAppearance(armoredFactory)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/armored_factory_${getBuildingAppearance(armoredFactory)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/armored_factory_${getBuildingAppearance(armoredFactory)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="armoredFactory?.level"
            shape="poly"
            coords="8,359,220,495,373,410,158,259"
            alt="panzer-factory"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#recruitment-modal-armored-factory"
            @mouseover="setCurrentTroop(troops?.tanks[0])"
        />
        <div
            v-if="aluminumSmelter?.level"
            :class="`city-building-image-wrapper aluminum-smelter-${getBuildingAppearance(aluminumSmelter)}`"
        >
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ aluminumSmelter?.name }}</div>
                <div class="city-building-level">Level {{ aluminumSmelter?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/aluminum_smelter_${getBuildingAppearance(aluminumSmelter)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="
                        require(`@/assets/buildings/aluminum_smelter_${getBuildingAppearance(aluminumSmelter)}_desktop.avif`)
                    "
                />
                <img
                    :src="require(`@/assets/buildings/aluminum_smelter_${getBuildingAppearance(aluminumSmelter)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="aluminumSmelter?.level"
            shape="poly"
            coords="497,395,678,506,826,429,770,288,714,256,590,302"
            alt="aluminium-factory"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#resource-building-modal"
            @mouseover="setResourceModalData(aluminumSmelter?.name)"
        />
        <div
            v-if="planeFactory?.level"
            :class="`city-building-image-wrapper plane-factory-${getBuildingAppearance(planeFactory)}`"
        >
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ planeFactory?.name }}</div>
                <div class="city-building-level">Level {{ planeFactory?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/plane_factory_${getBuildingAppearance(planeFactory)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/plane_factory_${getBuildingAppearance(planeFactory)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/plane_factory_${getBuildingAppearance(planeFactory)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="planeFactory?.level"
            shape="poly"
            coords="266,538,285,607,393,660,589,544,560,482,461,417"
            alt="planes-factory"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#recruitment-modal-plane-factory"
            @mouseover="setCurrentTroop(troops?.planes[0])"
        />
        <div
            v-if="generalOffice?.level"
            :class="`city-building-image-wrapper general-office-${getBuildingAppearance(generalOffice)}`"
        >
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ generalOffice?.name }}</div>
                <div class="city-building-level">Level {{ generalOffice?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/general_office_${getBuildingAppearance(generalOffice)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/general_office_${getBuildingAppearance(generalOffice)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/general_office_${getBuildingAppearance(generalOffice)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="generalOffice?.level"
            shape="poly"
            coords="887,471,1004,541,1080,495,1072,439,987,375,911,407"
            alt="general-offices"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#generals-office-modal"
            @mouseover="setCurrentTroop(troops?.other[0])"
        />
        <div v-if="airport?.level" :class="`city-building-image-wrapper airport-${getBuildingAppearance(airport)}`">
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ airport?.name }}</div>
                <div class="city-building-level">Level {{ airport?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/airport_${getBuildingAppearance(airport)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/airport_${getBuildingAppearance(airport)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/airport_${getBuildingAppearance(airport)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="airport?.level"
            shape="poly"
            coords="570,764,678,839,1038,634,800,536,610,651,672,699"
            alt="airport"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#airport-modal"
        />
        <div v-if="storage?.level" :class="`city-building-image-wrapper storage-${getBuildingAppearance(storage)}`">
            <div class="city-building-info-wrapper storage">
                <div class="city-building-name">{{ storage?.name }}</div>
                <div class="city-building-level">Level {{ storage?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/storage_${getBuildingAppearance(storage)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/storage_${getBuildingAppearance(storage)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/storage_${getBuildingAppearance(storage)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="storage?.level"
            shape="poly"
            coords="1352,161,1588,312,1724,228,1698,154,1507,68"
            alt="storage-building"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#storage-building-modal"
        />
        <div
            v-if="commercialZone?.level"
            :class="`city-building-image-wrapper commercial-zone-${getBuildingAppearance(commercialZone)}`"
        >
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ commercialZone?.name }}</div>
                <div class="city-building-level">Level {{ commercialZone?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/commercial_zone_${getBuildingAppearance(commercialZone)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/commercial_zone_${getBuildingAppearance(commercialZone)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/commercial_zone_${getBuildingAppearance(commercialZone)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="commercialZone?.level"
            shape="poly"
            coords="1382,443,1552,537,1716,441,1521,319"
            alt="trading-center"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#commercial-center-modal"
        />
        <div v-if="barracks?.level" :class="`city-building-image-wrapper barracks-${getBuildingAppearance(barracks)}`">
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ barracks?.name }}</div>
                <div class="city-building-level">Level {{ barracks?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/barracks_${getBuildingAppearance(barracks)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/barracks_${getBuildingAppearance(barracks)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/barracks_${getBuildingAppearance(barracks)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="barracks?.level"
            shape="poly"
            coords="1048,722,1228,831,1487,680,1297,574"
            alt="training-camp"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#recruitment-modal-barracks"
            @mouseover="setCurrentTroop(troops?.soldiers[0])"
        />
        <div
            v-if="fortifications?.level"
            :class="`city-building-image-wrapper fortifications-${getBuildingAppearance(fortifications)}`"
        >
            <div class="city-building-info-wrapper wall">
                <div class="city-building-name">{{ fortifications?.name }}</div>
                <div class="city-building-level">Level {{ fortifications?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/fortifications_${getBuildingAppearance(fortifications)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/fortifications_${getBuildingAppearance(fortifications)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/fortifications_${getBuildingAppearance(fortifications)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="fortifications?.level"
            shape="poly"
            coords="0,434,64,476,63,521,183,588,202,651,165,673,49,609,0,619"
            alt="wall"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#wall-modal"
        />
        <area
            v-if="fortifications?.level"
            shape="poly"
            coords="239,727,248,675,273,653,595,834,606,899,573,916,539,899,473,919,388,877,392,809"
            alt="wall"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#wall-modal"
        />
        <area
            v-if="fortifications?.level"
            shape="poly"
            coords="709,977,765,1015,938,916,1007,951,1082,911,1204,980,1669,719,1664,653,1628,633,1209,873,1006,753,724,909"
            alt="wall"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#wall-modal"
        />
        <area
            v-if="fortifications?.level"
            shape="poly"
            coords="1918,439,1745,539,1810,551,1815,627,1771,656,1591,559,1635,493,1759,443,1698,409,1703,346,1740,314,1722,198,1781,153,1839,190,1825,288,1918,341"
            alt="wall"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#wall-modal"
        />
        <div v-if="farm?.level" :class="`city-building-image-wrapper farm-${getBuildingAppearance(farm)}`">
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ farm?.name }}</div>
                <div class="city-building-level">Level {{ farm?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/farm_${getBuildingAppearance(farm)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/farm_${getBuildingAppearance(farm)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/farm_${getBuildingAppearance(farm)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="farm?.level"
            shape="poly"
            coords="1275,984,1415,1063,1740,875,1803,905,1829,878,1820,820,1666,731,1303,936"
            alt="farm"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#farm-modal"
        />
        <div v-if="farm?.level" :class="`city-building-image-wrapper farm-second-${getBuildingAppearance(farm)}`">
            <div class="city-building-info-wrapper">
                <div class="city-building-name">{{ farm?.name }}</div>
                <div class="city-building-level">Level {{ farm?.level }}</div>
            </div>
            <picture>
                <source
                    media="(max-width: 799px)"
                    :srcset="require(`@/assets/buildings/farm_second_${getBuildingAppearance(farm)}_mobile.avif`)"
                />
                <source
                    media="(min-width: 800px)"
                    :srcset="require(`@/assets/buildings/farm_second_${getBuildingAppearance(farm)}_desktop.avif`)"
                />
                <img
                    :src="require(`@/assets/buildings/farm_second_${getBuildingAppearance(farm)}_desktop.avif`)"
                    class="city-building-image"
                />
            </picture>
        </div>
        <area
            v-if="farm?.level"
            shape="poly"
            coords="18,926,163,1010,375,883,226,806,224,762,186,741,22,858"
            alt="farm-second"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#farm-modal"
        />
    </map>
</template>

<script>
import { BuildingIdentifier } from '@/utils/constants/city';
import { getBuildingAppearance } from '@/utils/functions/cityUtils';

export default {
    name: 'CityBuildingsLayer',
    props: {
        buildings: {
            type: Array,
            default: () => [],
        },
        troops: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            BuildingIdentifier,
        };
    },
    emits: ['set-resource-modal-data', 'set-current-troop'],
    computed: {
        mainBuilding() {
            return this.buildings.find(building => building.name === BuildingIdentifier.MAIN_BUILDING);
        },
        barracks() {
            return this.buildings.find(building => building.name === BuildingIdentifier.BARRACKS);
        },
        generalOffice() {
            return this.buildings.find(building => building.name === BuildingIdentifier.GENERAL_OFFICE);
        },
        aluminumSmelter() {
            return this.buildings.find(building => building.name === BuildingIdentifier.ALUMINUM_SMELTER);
        },
        metalMiningCamp() {
            return this.buildings.find(building => building.name === BuildingIdentifier.METAL_MINING_CAMP);
        },
        oilExtractor() {
            return this.buildings.find(building => building.name === BuildingIdentifier.OIL_EXTRACTOR);
        },
        armoredFactory() {
            return this.buildings.find(building => building.name === BuildingIdentifier.ARMORED_FACTORY);
        },
        planeFactory() {
            return this.buildings.find(building => building.name === BuildingIdentifier.PLANE_FACTORY);
        },
        airport() {
            return this.buildings.find(building => building.name === BuildingIdentifier.AIRPORT);
        },
        storage() {
            return this.buildings.find(building => building.name === BuildingIdentifier.STORAGE);
        },
        commercialZone() {
            return this.buildings.find(building => building.name === BuildingIdentifier.COMMERCIAL_ZONE);
        },
        farm() {
            return this.buildings.find(building => building.name === BuildingIdentifier.FARM);
        },
        fortifications() {
            return this.buildings.find(building => building.name === BuildingIdentifier.FORTIFICATIONS);
        },
    },
    methods: {
        getBuildingAppearance,
        setResourceModalData(building_name) {
            const BUILDING = this.buildings.find(building => building.name === building_name);
            this.$emit('set-resource-modal-data', BUILDING);
        },
        setCurrentTroop(troop) {
            this.$emit('set-current-troop', troop);
        },
    },
};
</script>

<style scoped>
.city-building-info-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    width: 20rem;
}

.city-building-info-wrapper.metal {
    left: 85%;
    top: 40%;
}

.city-building-info-wrapper.storage {
    left: 25%;
    top: 15%;
}

.city-building-info-wrapper.wall {
    bottom: 13rem;
}

.city-building-name {
    font-family: 'sansation', sans-serif;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: ghostwhite;
    text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}

.city-building-level {
    font-family: 'sansation', sans-serif;
    font-size: 1rem;
    line-height: 1rem;
    color: gold;
    text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}

.main-building-1 {
    width: 499px;
    height: 274px;
    top: 170px;
    left: 973px;
}

.main-building-2 {
    width: 545px;
    height: 320px;
    top: 126px;
    left: 922px;
}

.main-building-3 {
    width: 602px;
    height: 326px;
    top: 112px;
    left: 886px;
}

.metal-mining-camp-1 {
    width: 373px;
    height: 274px;
    top: 2px;
    left: 535px;
}

.metal-mining-camp-2 {
    width: 421px;
    height: 294px;
    top: -17px;
    left: 535px;
}

.metal-mining-camp-3 {
    width: 438px;
    height: 292px;
    top: 4px;
    left: 550px;
}

.oil-extractor-1 {
    width: 325px;
    height: 229px;
    top: 153px;
    left: 277px;
}

.oil-extractor-2 {
    width: 385px;
    height: 247px;
    top: 153px;
    left: 249px;
}

.oil-extractor-3 {
    width: 432px;
    height: 326px;
    top: 76px;
    left: 217px;
}

.armored-factory-1 {
    width: 400px;
    height: 252px;
    top: 265px;
    left: -14px;
}

.armored-factory-2 {
    width: 430px;
    height: 252px;
    top: 260px;
    left: -32px;
}

.armored-factory-3 {
    width: 432px;
    height: 274px;
    top: 252px;
    left: -32px;
}

.aluminum-smelter-1 {
    width: 372px;
    height: 244px;
    top: 278px;
    left: 486px;
}

.aluminum-smelter-2 {
    width: 380px;
    height: 262px;
    top: 258px;
    left: 462px;
}

.aluminum-smelter-3 {
    width: 427px;
    height: 310px;
    top: 241px;
    left: 454px;
}

.plane-factory-1 {
    width: 389px;
    height: 253px;
    top: 430px;
    left: 255px;
}

.plane-factory-2 {
    width: 424px;
    height: 266px;
    top: 410px;
    left: 246px;
}

.plane-factory-3 {
    width: 437px;
    height: 283px;
    top: 405px;
    left: 235px;
}

.general-office-1 {
    width: 268px;
    height: 164px;
    top: 375px;
    left: 860px;
}

.general-office-2 {
    width: 274px;
    height: 188px;
    top: 360px;
    left: 856px;
}

.general-office-3 {
    width: 288px;
    height: 216px;
    top: 334px;
    left: 848px;
}

.airport-1 {
    width: 462px;
    height: 266px;
    top: 570px;
    left: 550px;
}

.airport-2 {
    width: 553px;
    height: 331px;
    top: 535px;
    left: 548px;
}

.airport-3 {
    width: 602px;
    height: 348px;
    top: 522px;
    left: 510px;
}

.storage-1 {
    width: 380px;
    height: 311px;
    top: 50px;
    left: 1355px;
}

.storage-2 {
    width: 428px;
    height: 335px;
    top: 20px;
    left: 1332px;
}

.storage-3 {
    width: 432px;
    height: 328px;
    top: 40px;
    left: 1330px;
}

.commercial-zone-1 {
    width: 415px;
    height: 260px;
    top: 323px;
    left: 1331px;
}

.commercial-zone-2 {
    width: 394px;
    height: 233px;
    top: 335px;
    left: 1339px;
}

.commercial-zone-3 {
    width: 391px;
    height: 256px;
    top: 309px;
    left: 1340px;
}

.barracks-1 {
    width: 461px;
    height: 254px;
    top: 598px;
    left: 1013px;
}

.barracks-2 {
    width: 499px;
    height: 276px;
    top: 575px;
    left: 1000px;
}

.barracks-3 {
    width: 545px;
    height: 329px;
    top: 555px;
    left: 980px;
}

.fortifications-1 {
    width: 1920px;
    height: 810px;
    top: 218px;
    left: 0;
}

.fortifications-2 {
    width: 1920px;
    height: 872px;
    top: 152px;
    left: 0;
}

.fortifications-3 {
    width: 1920px;
    height: 897px;
    top: 130px;
    left: 0;
}

.farm-1 {
    width: 510px;
    height: 330px;
    top: 720px;
    left: 1365px;
}

.farm-2 {
    width: 710px;
    height: 373px;
    top: 728px;
    left: 1255px;
}

.farm-3 {
    width: 709px;
    height: 395px;
    top: 728px;
    left: 1255px;
}

.farm-second-1 {
    width: 430px;
    height: 298px;
    top: 745px;
    left: -20px;
}

.farm-second-2 {
    width: 448px;
    height: 324px;
    top: 745px;
    left: -20px;
}

.farm-second-3 {
    width: 470px;
    height: 338px;
    top: 740px;
    left: -35px;
}

.city-building-image-wrapper {
    position: absolute;
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
}

.city-building-image {
    width: 100%;
    height: 100%;
}
</style>
