import Collection from '@/classes/generic/Collection';

export default class MapActionsCollection extends Collection {
    constructor(actions) {
        super(actions);
    }

    startCountdowns() {
        this.items.forEach(action => action.startCountdown());
    }

    stopCountdowns() {
        this.items.forEach(action => action.stopCountdown());
    }
}
