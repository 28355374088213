<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Premium Shop</h5>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="legal-info-text">
                        <span class="legal-clickable" @click="openTermsAndConditionsModal"> Terms & Conditions </span> |
                        <span class="legal-clickable" @click="openPrivacyPolicyModal">
                            Privacy Policy & Protection of personal data
                        </span>
                        |
                        <span class="legal-clickable" @click="openContactModal"> Contact </span>
                        <span> All prices include tax </span>
                    </div>
                    <ul class="nav nav-tabs" id="shop-nav" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="buy-pp"
                                data-bs-toggle="tab"
                                data-bs-target="#buy-pp-page"
                                type="button"
                                role="tab"
                                aria-controls="buy-pp-tab"
                                aria-selected="true"
                            >
                                Buy PP
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="use-pp"
                                data-bs-toggle="tab"
                                data-bs-target="#use-pp-page"
                                type="button"
                                role="tab"
                                aria-controls="use-pp-tab"
                                aria-selected="false"
                            >
                                Use PP
                            </button>
                        </li>
                        <li class="nav-filler">
                            <select class="form-select" aria-label="">
                                <option selected>Payment Method 1</option>
                                <option value="1">Payment Method 2</option>
                                <option value="2">Payment Method 3</option>
                                <option value="3">Payment Method 4</option>
                            </select>
                        </li>
                    </ul>
                    <div class="tab-content" id="shop-pages">
                        <div class="tab-pane fade show active" id="buy-pp-page" role="tabpanel" aria-labelledby="buy-pp-tab">
                            <div class="buy-offers-wrapper">
                                <div class="offer-card">
                                    <div class="offer-card-title">
                                        <span class="offer-amount"> 200 </span>
                                        <span class="offer-currency"> Premium Points </span>
                                    </div>
                                    <div class="offer-image-wrapper">
                                        <img src="../assets/icons/medal_icon.png" class="offer-image" />
                                    </div>
                                    <button class="btn yellow-button">22.00 RON</button>
                                </div>
                                <div class="offer-card">
                                    <div class="offer-card-title">
                                        <span class="promo"> <s>398</s> +13% </span>
                                        <span class="offer-amount"> 450 </span>
                                        <span class="offer-currency"> Premium Points </span>
                                    </div>
                                    <div class="offer-image-wrapper">
                                        <img src="../assets/icons/medal_icon.png" class="offer-image second" />
                                    </div>
                                    <button class="btn yellow-button">44.00 RON</button>
                                </div>
                                <div class="offer-card">
                                    <div class="offer-card-title">
                                        <span class="promo"> <s>800</s> +25% </span>
                                        <span class="offer-amount"> 1000 </span>
                                        <span class="offer-currency"> Premium Points </span>
                                    </div>
                                    <div class="offer-image-wrapper">
                                        <img src="../assets/icons/medal_icon.png" class="offer-image third" />
                                    </div>
                                    <button class="btn yellow-button">88.00 RON</button>
                                </div>
                                <div class="offer-card">
                                    <div class="offer-card-title">
                                        <span class="promo"> <s>2000</s> +75% </span>
                                        <span class="offer-amount"> 3500 </span>
                                        <span class="offer-currency"> Premium Points </span>
                                    </div>
                                    <div class="offer-image-wrapper">
                                        <img src="../assets/icons/medal_icon.png" class="offer-image fourth" />
                                    </div>
                                    <button class="btn yellow-button">220.00 RON</button>
                                </div>
                                <div class="offer-card">
                                    <div class="offer-card-title">
                                        <span class="promo"> <s>3271</s> +114% </span>
                                        <span class="offer-amount"> 7000 </span>
                                        <span class="offer-currency"> Premium Points </span>
                                    </div>
                                    <div class="offer-image-wrapper">
                                        <img src="../assets/icons/medal_icon.png" class="offer-image fifth" />
                                    </div>
                                    <button class="btn yellow-button">360.00 RON</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="use-pp-page" role="tabpanel" aria-labelledby="use-pp-tab">
                            <div class="use-offers-wrapper">
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/construction_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/metal_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/oil_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/aluminum_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/rations_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/map_pin_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/inform_circle_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/oil_circle_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                                <div class="use-offer-card">
                                    <div class="use-offer-title">
                                        <span class="use-offer-name"> Lorem ipsum </span>
                                        <span class="use-offer-active">
                                            <span>Active until:</span>
                                            <span>03/11/2023 11:54 AM</span>
                                        </span>
                                    </div>
                                    <img src="../assets/icons/aluminum_circle_icon.svg" class="use-offer-image" />
                                    <p class="use-offer-description">
                                        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </p>
                                    <button class="btn yellow-button d-flex justify-content-evenly">
                                        <span>200 PP</span> |
                                        <span>Buy Now</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShopModal',
    data() {
        return {};
    },
    created() {},
    methods: {
        openTermsAndConditionsModal() {
            document.getElementById('terms-and-conditions-modal').style.transform = 'translateY(-20px)';
            document.getElementById('terms-and-conditions-modal').style.opacity = '0';
            document.getElementById('terms-and-conditions-modal').style.display = 'block';
            document.getElementById('terms-and-conditions-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('terms-and-conditions-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('terms-and-conditions-modal').style.transform = 'translateY(0)';
                document.getElementById('terms-and-conditions-modal').style.opacity = '1';
            }, 0);
        },
        openPrivacyPolicyModal() {
            document.getElementById('privacy-policy-modal').style.transform = 'translateY(-20px)';
            document.getElementById('privacy-policy-modal').style.opacity = '0';
            document.getElementById('privacy-policy-modal').style.display = 'block';
            document.getElementById('privacy-policy-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('privacy-policy-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('privacy-policy-modal').style.transform = 'translateY(0)';
                document.getElementById('privacy-policy-modal').style.opacity = '1';
            }, 0);
        },
        openContactModal() {
            document.getElementById('contact-info-modal').style.transform = 'translateY(-20px)';
            document.getElementById('contact-info-modal').style.opacity = '0';
            document.getElementById('contact-info-modal').style.display = 'block';
            document.getElementById('contact-info-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('contact-info-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('contact-info-modal').style.transform = 'translateY(0)';
                document.getElementById('contact-info-modal').style.opacity = '1';
            }, 0);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
    flex-wrap: nowrap;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    display: flex;
    flex-grow: 1;
    border-bottom: 2px solid #434343;
    padding: 0 0 0.5rem 0.5rem;
    align-items: flex-end;
    justify-content: flex-end;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.row {
    margin: 0;
    gap: 2px;
    flex-wrap: nowrap;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
    min-height: 2.5rem;
    line-height: 1rem;
}

.form-select {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    color: ghostwhite;
    border-color: ghostwhite;
    filter: brightness(10);
    font-size: 0.875rem;
    cursor: pointer;
    height: 100%;
    max-width: 12rem;
    max-height: 41px;
}

.form-select:focus {
    box-shadow: none;
    border-color: ghostwhite;
}

.btn {
    padding: 0.25rem 1rem;
    width: 100%;
    height: fit-content;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.buy-offers-wrapper {
    display: flex;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.offer-card {
    background: rgba(0, 0, 0, 0.5);
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    border: 2px solid burlywood;
    width: 18%;
}

.offer-card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: 100%;
    min-height: 5rem;
    justify-content: center;
}

.promo {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    letter-spacing: 1px;
}

.promo s {
    opacity: 0.6;
}

.offer-amount {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
    color: goldenrod;
}

.offer-currency {
    line-height: 1rem;
    opacity: 0.9;
}

.offer-image-wrapper {
    width: 100%;
    min-height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offer-image {
    width: 3rem;
    height: 3rem;
}

.offer-image.second {
    transform: scale(1.2);
}

.offer-image.third {
    transform: scale(1.4);
}

.offer-image.fourth {
    transform: scale(1.6);
}

.offer-image.fifth {
    transform: scale(1.8);
}

.legal-clickable {
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
}

.legal-clickable:hover {
    text-decoration: none;
    color: cornflowerblue;
}

.legal-info-text {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.125rem;
    position: absolute;
    top: 0.5rem;
    color: slategrey;
    font-size: 0.75rem;
    width: calc(100% - 2rem);
}

.legal-info-text > span:not(.legal-clickable) {
    margin-left: 2%;
    white-space: nowrap;
}

.use-offers-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.use-offer-card {
    background: rgba(0, 0, 0, 0.5);
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    border: 2px solid #434343;
    width: calc(33% - 3px);
}

.use-offer-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: 100%;
}

.use-offer-name {
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 600;
    width: calc(100% + 2rem);
    padding: 0.75rem 1rem;
    text-align: center;
    color: gainsboro;
    text-transform: uppercase;
}

.use-offer-active {
    display: flex;
    justify-content: space-between;
    column-gap: 0.25rem;
    width: calc(100% + 2rem);
    padding: 0 1rem;
    background: #434343;
    color: gainsboro;
    flex-wrap: wrap;
}

.use-offer-image {
    margin-top: 10%;
    width: 30%;
    height: 30%;
}

.use-offer-description {
    text-align: center;
    font-size: 0.9375rem;
    line-height: 1rem;
    color: lightgrey;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 991px) {
    .use-offer-name {
        font-size: 1.125rem;
        line-height: 1.125rem;
    }
    .use-offer-active {
        font-size: 0.875rem;
        line-height: 1rem;
    }
    .use-offer-description {
        font-size: 0.875rem;
        line-height: 0.875rem;
    }
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        min-width: 2rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
}

@media screen and (max-width: 850px) {
    .use-offer-name {
        font-size: 1rem;
        line-height: 1rem;
    }
    .use-offer-active {
        font-size: 0.75rem;
        line-height: 0.875rem;
    }
    .use-offer-description {
        font-size: 0.75rem;
        line-height: 0.875rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    .tab-pane {
        padding: 2rem 1rem 1rem 1rem;
    }
}

@media screen and (max-width: 450px) {
    .col {
        font-size: 0.625rem;
    }
}

@media screen and (max-width: 420px) {
    .modal-body {
        padding: 3.5rem 1rem 1rem 1rem;
    }
    .legal-info-text {
        line-height: 0.875rem;
    }
    .offer-card {
        min-width: 8rem;
        padding: 0.5rem;
    }
    .offer-card-title {
        min-height: 4rem;
    }
    .promo {
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }
    .offer-amount {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .offer-currency {
        font-size: 0.875rem;
        line-height: 0.875rem;
        text-align: center;
    }
    .offer-image-wrapper {
        min-height: 5rem;
    }
    .offer-image {
        width: 2.5rem;
        height: 2.5rem;
    }
    .use-offer-card {
        width: 70%;
    }
}

@media screen and (max-width: 350px) {
    .offer-card {
        width: 80%;
    }
    .use-offer-card {
        width: 80%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
