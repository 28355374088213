<template>
    <div class="troop-info-hover-panel">
        <div class="troop-info-rows-wrapper">
            <div v-for="troop_name in troopNames" :key="troop_name" class="troop-info-row">
                <div class="troop-image-wrapper">
                    <img :src="require(`../../assets/icons/troops/${paramCase(troop_name)}-tall.avif`)" class="troop-image" />
                </div>
                <span class="troop-number">
                    {{ troops[troop_name] }}
                </span>
            </div>
        </div>
        <span
            v-if="!isIntercept"
            class="go-to-target-button"
            @click="$emit('scroll-to-target', target._id, { x: target.x, y: target.y })"
            @touchstart.stop
            @mousedown.stop
        >
            {{ target.name }} ( {{ target.x }} | {{ target.y }} )
        </span>
    </div>
</template>

<script>
import { paramCase } from 'change-case';
import { mapGetters } from 'vuex';

export default {
    name: 'TroopsInfoPanel',
    props: {
        troops: {
            type: Object,
            default: () => {},
        },
        target: {
            type: Object,
            default: () => {},
        },
        isIntercept: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        troopNames() {
            return Object.keys(this.troops);
        },
        mapScaleFactor() {
            return this.getMapScaleFactor();
        },
    },
    emits: ['scroll-to-target'],
    methods: {
        paramCase,
        ...mapGetters(['getMapScaleFactor']),
    },
};
</script>

<style scoped>
.troop-info-hover-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: max-content;
    height: auto;
    bottom: calc(125% / v-bind(mapScaleFactor));
    left: 50%;
    transform: translateX(-50%) scale(v-bind(1 / mapScaleFactor));
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid darkgray;
    padding: 0.5rem;
}

.troop-info-rows-wrapper {
    column-count: 3;
    column-gap: 1rem;
    break-inside: avoid;
}

.troop-info-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.troop-image-wrapper {
    min-width: 1.75rem;
    height: 1.75rem;
    overflow: hidden;
}

.troop-image {
    width: 1.75rem;
    cursor: pointer;
}

.troop-number {
    font-size: 0.875rem;
    color: ghostwhite;
}

.go-to-target-button {
    color: cornflowerblue;
    text-decoration: underline;
    font-size: 0.75rem;
    line-height: 1;
    cursor: pointer;
    -moz-osx-font-smoothing: unset;
    -webkit-font-smoothing: unset;
}

.go-to-target-button:hover {
    text-decoration: none;
}
</style>
