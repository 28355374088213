export const CitySearchOptionIdentifier = Object.freeze({
    COORDS: 'coords',
    CITY_NAME: 'city_name',
    PLAYER_NAME: 'player_name',
});

export const MAP_TILES_HORIZONTALLY = 38;
export const MAP_TILES_VERTICALLY = 38;

export const MAP_TILE_WIDTH = 580;
export const MAP_TILE_HEIGHT = 335;

export const WORLD_MAP_WIDTH = MAP_TILE_WIDTH * MAP_TILES_HORIZONTALLY;
export const WORLD_MAP_HEIGHT = MAP_TILE_HEIGHT * MAP_TILES_VERTICALLY;

export const CITIES_PER_TILE_HORIZONTALLY = 4;
export const CITIES_PER_TILE_VERTICALLY = 4;

export const CITY_TILES_HORIZONTALLY = MAP_TILES_HORIZONTALLY * CITIES_PER_TILE_HORIZONTALLY;
export const CITY_TILES_VERTICALLY = MAP_TILES_VERTICALLY * CITIES_PER_TILE_VERTICALLY;

export const CITY_TILE_WIDTH = MAP_TILE_WIDTH / CITIES_PER_TILE_HORIZONTALLY;
export const CITY_TILE_HEIGHT = MAP_TILE_HEIGHT / CITIES_PER_TILE_VERTICALLY;

export const DECORATION_TILE_WIDTH = MAP_TILE_WIDTH / CITIES_PER_TILE_HORIZONTALLY;
export const DECORATION_TILE_HEIGHT = MAP_TILE_HEIGHT / CITIES_PER_TILE_VERTICALLY;

export const PIXEL_RATIO = window.devicePixelRatio || 1;

export const MINIMAP_HORIZONTAL_SCALE = 0.0625;
// export const MINIMAP_VERTICAL_SCALE = 0.1298508;
export const MINIMAP_VERTICAL_SCALE = 0.0625;


export const MINIMAP_POINT_WIDTH = CITY_TILE_WIDTH * MINIMAP_HORIZONTAL_SCALE;
export const MINIMAP_POINT_HEIGHT = CITY_TILE_HEIGHT * MINIMAP_VERTICAL_SCALE;

export const MINIMAP_WIDTH = MINIMAP_POINT_WIDTH * CITY_TILES_HORIZONTALLY;
export const MINIMAP_HEIGHT = MINIMAP_POINT_HEIGHT * CITY_TILES_VERTICALLY;
