import { CITIES_PER_TILE_HORIZONTALLY, CITIES_PER_TILE_VERTICALLY } from '@/utils/constants/map';
import { getCityLevel } from '@/utils/functions/cityUtils';
import { CityColorLabels } from '@/utils/constants/city';

export function getCityMapColor({ state, user, city }) {
    if (user.isBarbarian) {
        return CityColorLabels.BARBARIAN;
    }
    const isOwnCity = user._id === state.userState._id;
    if (isOwnCity) {
        const isCurrentCity = city._id === state.currentCity._id;
        if (isCurrentCity) {
            return CityColorLabels.CURRENT_CITY;
        } else {
            return CityColorLabels.OWNED_CITY;
        }
    } else {
        const isUserFriend = state.userState.friends.find(friend => friend === user._id);
        if (isUserFriend) {
            return CityColorLabels.FRIEND;
        }

        const isUserInSameCoalition = state.userState.coalition?.allMembers.find(member => member._id === user._id);
        if (isUserInSameCoalition) {
            return CityColorLabels.COALITION_MEMBER;
        }

        const isUserAnAlly = state.userState.coalition?.alliances.find(ally => ally._id === user.coalition?._id);
        if (isUserAnAlly) {
            return CityColorLabels.ALLY;
        }

        const isUserInNap = state.userState.coalition?.nonAggressionPacts.find(
            nap_coalition => nap_coalition._id === user.coalition?._id,
        );
        if (isUserInNap) {
            return CityColorLabels.NAP;
        }

        const isUserAnEnemy = state.userState.coalition?.wars.find(
            war => war.initiatorCoalition._id === user.coalition?._id || war.targetCoalition._id === user.coalition?._id,
        );
        if (isUserAnEnemy) {
            return CityColorLabels.ENEMY;
        }

        return CityColorLabels.OTHER;
    }
}

export function addAdditionalPropertiesToCity({ state, user, city }) {
    city.color = getCityMapColor({ state, user, city });
    city.localX = city.x % CITIES_PER_TILE_HORIZONTALLY;
    city.localY = city.y % CITIES_PER_TILE_VERTICALLY;

    city.level = getCityLevel(city.points);
    city.isBarbarian = user.isBarbarian;
}

export function addCityToMap({ state, city }) {
    const TileX = Math.floor(city.x / CITIES_PER_TILE_HORIZONTALLY);
    const TileY = Math.floor(city.y / CITIES_PER_TILE_VERTICALLY);

    const FoundTile = state.mapMatrix[TileY][TileX];
    FoundTile.cities.push(city);
}
