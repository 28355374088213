import Collection from "@/classes/generic/Collection";

export default class Troop {
	originalData = null;
	reinforcements = new Collection([]);

	constructor(troop) {
		this.originalData = troop;
	}
}
