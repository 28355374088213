<template>
    <!-- TO DO: add tooltips to icons after you create reusable tooltip component -->
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" @click="closeModal">
                        <img src="../../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
					<div class="warning-message">{{ warningMessage }}</div>
                    <div class="trades-number-input-wrapper">
                        <div class="row" :class="{ outline: offer?.newValue === true }">
                            <div class="col small standalone-label">Offers to accept</div>
                            <div class="col large">
                                <input
                                    v-model="offersToAccept"
                                    type="number"
                                    min="0"
                                    :max="offer?.repeats"
									:disabled="offer?.repeats === 0"
                                    class="form-control"
                                />
                                <span class="max-send-resources" @click="addRemainingOffers"
                                    >+{{ Math.max(maxOffersToAccept - offersToAccept, 0) }}</span
                                >
                            </div>
                        </div>
                        <div v-if="needToOfferAny" class="row">
                            <div class="col small standalone-label">Resources needed</div>
                            <div class="col large">
                                <img src="../../assets/icons/interface/storage_icon.svg" class="offer-icon" />
                                <span>{{ resourcesLeftToSend }}</span>
                            </div>
                        </div>
                        <div v-if="!needToOfferAny" class="row">
                            <div class="col small standalone-label">Resources needed</div>
                            <div class="col large">
                                <img
                                    :src="require(`../../assets/icons/${offer?.resourceToGet?.name || 'aluminum'}_icon.svg`)"
                                    class="offer-icon"
                                />
                                <span>{{ maxResourcesToSend }}</span>
                            </div>
                        </div>
                        <div v-if="!needToOfferAny" class="row">
                            <div class="col small standalone-label">Resources available</div>
                            <div class="col large">
                                <img
                                    :src="require(`../../assets/icons/${offer?.resourceToGet?.name || 'aluminum'}_icon.svg`)"
                                    class="offer-icon"
                                />
                                <span>{{ resources[offer?.resourceToGet?.name] }}</span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col small standalone-label">Traders needed</div>
                            <div class="col large">
                                {{ tradersNeededForTrade }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col small standalone-label">Traders available</div>
                            <div class="col large">
                                {{ availableTraders }}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col small standalone-label">Trucks needed</div>
                            <div class="col large">
                                {{ trucksNeededForTrade }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col small standalone-label">Trucks available</div>
                            <div class="col large">
                                {{ availableTrucks }}
                            </div>
                        </div>
                    </div>
					<div class="truck-select-table">
						<div class="row label">
							<div class="col">Unit</div>
							<div class="col">Owned</div>
							<div class="col">Controlled</div>
						</div>
						<TruckSelect :current-city-id="currentCityId" :trucks="trucks" :reinforcements="reinforcements" />
					</div>
                    <div v-if="needToOfferAny" class="resource-inputs-wrapper mt-4">
                        <div class="row label">
                            <div class="col medium">Resource</div>
                            <div class="col small">Available</div>
                            <div class="col large">Offered</div>
                        </div>
                        <div v-if="!needToReceiveAluminum" class="row">
                            <div class="col medium">
                                <span>Aluminum</span>
                                <img src="../../assets/icons/aluminum_icon.svg" class="offer-icon" />
                            </div>
                            <div class="col small">{{ resources.aluminum }}</div>
                            <div class="col large">
                                <input
                                    v-model="aluminum"
                                    type="number"
                                    min="0"
									step="100"
                                    :max="Math.min(aluminum + resourcesLeftToSend, resources.aluminum)"
									:disabled="offersToAccept === 0"
                                    class="form-control"
                                />
                                <span class="max-send-resources" @click="addRemainingResources(ResourceIdentifier.ALUMINUM)"
                                    >+{{ Math.min(resourcesLeftToSend, Math.max(resources.aluminum - aluminum, 0)) }}</span
                                >
                            </div>
                        </div>
                        <div v-if="!needToReceiveOil" class="row">
                            <div class="col medium">
                                <span>Oil</span>
                                <img src="../../assets/icons/oil_icon.svg" class="offer-icon" />
                            </div>
                            <div class="col small">{{ resources.oil }}</div>
                            <div class="col large">
                                <input
                                    v-model="oil"
                                    type="number"
                                    min="0"
									step="100"
                                    :max="Math.min(oil + resourcesLeftToSend, resources.oil)"
									:disabled="offersToAccept === 0"
                                    class="form-control"
                                />
                                <span class="max-send-resources" @click="addRemainingResources(ResourceIdentifier.OIL)"
                                    >+{{ Math.min(resourcesLeftToSend, Math.max(resources.oil - oil, 0)) }}</span
                                >
                            </div>
                        </div>
                        <div v-if="!needToReceiveMetal" class="row">
                            <div class="col medium">
                                <span>Metal</span>
                                <img src="../../assets/icons/metal_icon.svg" class="offer-icon" />
                            </div>
                            <div class="col small">{{ resources.metal }}</div>
                            <div class="col large">
                                <input
                                    v-model="metal"
                                    type="number"
                                    min="0"
									step="100"
                                    :max="Math.min(metal + resourcesLeftToSend, resources.metal)"
									:disabled="offersToAccept === 0"
                                    class="form-control"
                                />
                                <span class="max-send-resources" @click="addRemainingResources(ResourceIdentifier.METAL)"
                                    >+{{ Math.min(resourcesLeftToSend, Math.max(resources.metal - metal, 0)) }}</span
                                >
                            </div>
                        </div>
                        <div v-if="!needToReceiveRations" class="row">
                            <div class="col medium">
                                <span>Rations</span>
                                <img src="../../assets/icons/rations_icon.svg" class="offer-icon" />
                            </div>
                            <div class="col small">{{ resources.rations }}</div>
                            <div class="col large">
                                <input
                                    v-model="rations"
                                    type="number"
                                    min="0"
									step="100"
                                    :max="Math.min(rations + resourcesLeftToSend, resources.rations)"
									:disabled="offersToAccept === 0"
                                    class="form-control"
                                />
                                <span class="max-send-resources" @click="addRemainingResources(ResourceIdentifier.RATIONS)"
                                    >+{{ Math.min(resourcesLeftToSend, Math.max(resources.rations - rations, 0)) }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center gap-3 mt-5">
                        <button class="btn yellow-button" :disabled="offersToAccept === 0" @click="acceptOfferCommand">Confirm</button>
                        <button class="btn red-button" @click="closeModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ResourceIdentifier } from '@/utils/constants/trading';
import { mapActions } from 'vuex';
import TruckSelect from "@/components/CommercialCenter/TruckSelect";

export default {
    name: 'TradeConfirmationModal',
	components: { TruckSelect },
    props: {
        offer: {
            type: Object,
            default: () => {},
        },
        resources: {
            type: Object,
            default: () => {},
        },
        availableTraders: {
            type: Number,
            default: 0,
        },
        singleTraderCapacity: {
            type: Number,
            default: 0,
        },
        availableTrucks: {
            type: Number,
            default: 0,
        },
        singleTruckCapacity: {
            type: Number,
            default: 0,
        },
		trucks: {
			type: Object,
			default: () => {},
		},
		currentCityId: {
			type: String,
			default: '',
		},
		reinforcements: {
			type: Array,
			default: () => [],
		},
    },
    data() {
        return {
            ResourceIdentifier,

            offersToAccept: 1,

            aluminum: 0,
            oil: 0,
            metal: 0,
            rations: 0,

			warningMessage: '',
        };
    },
    computed: {
        needToOfferAny() {
            return this.offer?.resourceToGet?.name === ResourceIdentifier.ANY;
        },
        needToReceiveAluminum() {
            return this.offer?.resourceToOffer?.name === ResourceIdentifier.ALUMINUM;
        },
        needToReceiveOil() {
            return this.offer?.resourceToOffer?.name === ResourceIdentifier.OIL;
        },
        needToReceiveMetal() {
            return this.offer?.resourceToOffer?.name === ResourceIdentifier.METAL;
        },
        needToReceiveRations() {
            return this.offer?.resourceToOffer?.name === ResourceIdentifier.RATIONS;
        },
        relevantResourcesAvailable() {
            let ResourcesAvailable = 0;
            if (this.offer?.resourceToGet?.name === ResourceIdentifier.ANY) {
                Object.keys(this.resources).forEach(resource => {
                    if (resource !== this.offer?.resourceToOffer?.name) {
                        ResourcesAvailable += this.resources[resource];
                    }
                });
            } else {
                ResourcesAvailable = this.resources[this.offer?.resourceToGet?.name];
            }

            return ResourcesAvailable;
        },
        maxResourcesToSend() {
            if (!this.offer) {
                return 0;
            }
            return this.offer?.resourceToGet?.quantity * this.offersToAccept;
        },
        maxOffersToAccept() {
            if (!this.offer) {
                return 0;
            }
            return Math.min(
                Math.floor(this.relevantResourcesAvailable / this.offer?.resourceToGet?.quantity),
                Math.floor((this.availableTraders * this.singleTraderCapacity) / this.offer?.resourceToGet?.quantity),
				Math.floor((this.availableTrucks * this.singleTruckCapacity) / this.offer?.resourceToGet?.quantity),
                this.offer?.repeats,
            );
        },
        resourcesSelected() {
            return this.aluminum + this.oil + this.metal + this.rations;
        },
        resourcesLeftToSend() {
            return this.maxResourcesToSend - this.resourcesSelected > 0 ? this.maxResourcesToSend - this.resourcesSelected : 0;
        },
        tradersNeededForTrade() {
            return Math.ceil(this.maxResourcesToSend / this.singleTraderCapacity);
        },
        trucksNeededForTrade() {
            return Math.ceil(this.maxResourcesToSend / this.singleTruckCapacity);
        },
    },
	watch: {
		'offer.repeats': {
			handler(newVal) {
				if (newVal === 0) {
					this.offersToAccept = 0;
					this.warningMessage = 'Offer became unavailable';
				} else if (newVal < this.offersToAccept) {
					this.offersToAccept = newVal;
					this.warningMessage = 'Number of offers decreased, please adjust resources/trucks';
				}
			}
		}
	},
    methods: {
        ...mapActions(['acceptOffer']),
        resetState() {
            this.offersToAccept = 1;
            this.aluminum = 0;
            this.oil = 0;
            this.metal = 0;
            this.rations = 0;
			this.warningMessage = '';
        },

        closeModal() {
            document.getElementById('trade-confirmation-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('trade-confirmation-modal').style.transform = 'translateY(-20px)';
            document.getElementById('trade-confirmation-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('trade-confirmation-modal').style.display = 'none';
                document.getElementById('trade-confirmation-modal').classList.remove('show');
                document.getElementById('trade-confirmation-modal').style.removeProperty('transform');
                document.getElementById('trade-confirmation-modal').style.removeProperty('opacity');
            }, 150);
            this.resetState();
        },

        addRemainingResources(resource) {
            this[resource] = Math.min(this[resource] + this.resourcesLeftToSend, this.resources[resource]);
        },

        addRemainingOffers() {
            this.offersToAccept = this.maxOffersToAccept;
        },

        getResourcesForAcceptOffer() {
            const Resources = [];

            if (this.needToOfferAny) {
                if (this.aluminum > 0) {
                    Resources.push({ name: ResourceIdentifier.ALUMINUM, quantity: this.aluminum });
                }
                if (this.oil > 0) {
                    Resources.push({ name: ResourceIdentifier.OIL, quantity: this.oil });
                }
                if (this.metal > 0) {
                    Resources.push({ name: ResourceIdentifier.METAL, quantity: this.metal });
                }
                if (this.rations > 0) {
                    Resources.push({ name: ResourceIdentifier.RATIONS, quantity: this.rations });
                }
            } else {
                Resources.push({ name: this.offer?.resourceToOffer?.name, quantity: this.maxResourcesToSend });
            }

            return Resources;
        },

        async acceptOfferCommand() {
            await this.acceptOffer({
                offer: this.offer._id,
                repeats: this.offersToAccept,
                resources: this.getResourcesForAcceptOffer(),
            });
            this.closeModal();
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    padding: 0;
    border: none;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 100%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 8vh;
    padding: 0;
    background-image: url('../../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 2rem 2rem 2rem;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-control:disabled {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
	color: ghostwhite;
	border: 1px solid ghostwhite;
	opacity: 0.25;
}

.trades-number-input-wrapper,
.resource-inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.warning-message {
	width: 100%;
	text-align: center;
	color: #d34848;
	min-height: 2rem;
}

.offer-icon {
    height: 1rem;
    min-width: 2rem;
}

.max-send-resources {
    color: wheat;
}

.max-send-resources:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.standalone-label {
    background: linear-gradient(to bottom, #2a2a2a, #181818) !important;
    color: ghostwhite;
    min-height: 2rem;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    min-width: 4.5rem;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
}

.large {
    flex-basis: 45%;
}

.medium {
    flex-basis: 30%;
}

.small {
    flex-basis: 25%;
}

.truck-select-table {
	display: flex;
	flex-direction: column;
	gap: 2px;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.row.outline {
	outline: 1px solid silver;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
}

@media screen and (max-width: 750px) {
    .resource-inputs-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
    .modal-content {
        padding: 2rem 1rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 320px) {
    .modal-content {
        padding: 1.5rem 0.375rem;
    }
    .resource-inputs-wrapper .col {
        gap: 0.5rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
