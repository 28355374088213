<template>
    <div
        class="city-tile-wrapper"
        :style="`
			width: ${CITY_TILE_WIDTH}px;
			height: ${CITY_TILE_HEIGHT}px;
			left: ${CITY_TILE_WIDTH * city.localX}px;
			top: ${CITY_TILE_HEIGHT * city.localY}px;
		`"
    >
        <!--        <span style="position: absolute; bottom: 10px; left: 10px; color: white; z-index: 1000">{{ city.x }} | {{ city.y }}</span>-->
        <div
            class="city-clickable-overlay"
            @click="
                toggleMenuVisible();
                setTargetCity(city);
            "
            @mouseover="setTooltipVisible(true)"
            @mouseleave="setTooltipVisible(false)"
        />
        <picture>
            <source
                media="(max-width: 799px)"
                :srcset="require(`../../assets/map_tiles/${imagePrefix}${city.level}_mobile.avif`)"
            />
            <source
                media="(min-width: 800px)"
                :srcset="require(`../../assets/map_tiles/${imagePrefix}${city.level}_desktop.avif`)"
            />
            <img
                class="city-image"
                :class="{ highlighted: isHighlighted }"
                :src="require(`../../assets/map_tiles/${imagePrefix}${city.level}_desktop.avif`)"
                draggable="false"
            />
        </picture>
        <CityInfo :city="city" :current-city-id="currentCityId" :map-scale-factor="mapScaleFactor" />
        <CityActionsMenu :city="city" :menu-visible="menuVisible" :map-scale-factor="mapScaleFactor" />
        <CityTooltip :is-tooltip-visible="isTooltipVisible" :map-scale-factor="mapScaleFactor" />
    </div>
</template>

<script>
import { CITY_TILE_WIDTH, CITY_TILE_HEIGHT } from '@/utils/constants/map';
import CityActionsMenu from '@/components/WorldMap/CityActionsMenu';
import CityTooltip from '@/components/WorldMap/CityTooltip';
import CityInfo from '@/components/WorldMap/CityInfo';

export default {
    name: 'CityTile',
    components: { CityInfo, CityTooltip, CityActionsMenu },
    props: {
        city: {
            type: Object,
            default: () => {},
        },
        isBeingCenteredOn: {
            type: Boolean,
            default: false,
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
        currentCityId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            CITY_TILE_WIDTH,
            CITY_TILE_HEIGHT,

            menuVisible: false,
            tooltipVisible: false,
        };
    },
    computed: {
        isHighlighted() {
            return this.isBeingCenteredOn || this.menuVisible;
        },
        isTooltipVisible() {
            return this.tooltipVisible && !this.menuVisible;
        },
        imagePrefix() {
            // TO DO: extract strings into enum
            return this.city.isBarbarian ? 'city_destroyed_' : 'city_';
        },
    },
    methods: {
        setTargetCity(city) {
            const CityCopy = structuredClone(city);
            CityCopy.coords = {
                x: city.x,
                y: city.y,
            };
            this.$store.commit('setMapActionTarget', CityCopy);
        },
        toggleMenuVisible() {
            this.menuVisible = !this.menuVisible;
        },
        setTooltipVisible(value) {
            this.tooltipVisible = value;
        },
        openCommercialCenterModal() {
            // reset all tabs
            document.getElementById('exchange').classList.remove('active');
            document.getElementById('exchange').ariaSelected = 'false';
            document.getElementById('exchange-page').classList.remove('active', 'show');

            document.getElementById('create-offer').classList.remove('active');
            document.getElementById('create-offer').ariaSelected = 'false';
            document.getElementById('create-offer-page').classList.remove('active', 'show');

            document.getElementById('send-resources').classList.remove('active');
            document.getElementById('send-resources').ariaSelected = 'false';
            document.getElementById('send-resources-page').classList.remove('active', 'show');

            document.getElementById('transports').classList.remove('active');
            document.getElementById('transports').ariaSelected = 'false';
            document.getElementById('transports-page').classList.remove('active', 'show');

            // open send-resources tab
            document.getElementById('send-resources').classList.add('active');
            document.getElementById('send-resources').ariaSelected = 'true';
            document.getElementById('send-resources-page').classList.add('active', 'show');

            // open modal
            document.getElementById('commercial-center-modal').style.transform = 'translateY(-20px)';
            document.getElementById('commercial-center-modal').style.opacity = '0';
            document.getElementById('commercial-center-modal').style.display = 'block';
            document.getElementById('commercial-center-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('commercial-center-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('commercial-center-modal').style.transform = 'translateY(0)';
                document.getElementById('commercial-center-modal').style.opacity = '1';
            }, 0);
        },
        openMessageModal() {
            // reset all tabs
            document.getElementById('inbox').classList.remove('active');
            document.getElementById('inbox').ariaSelected = 'false';
            document.getElementById('inbox-page').classList.remove('active', 'show');

            document.getElementById('coalition').classList.remove('active');
            document.getElementById('coalition').ariaSelected = 'false';
            document.getElementById('coalition-page').classList.remove('active', 'show');

            document.getElementById('write-message').classList.remove('active');
            document.getElementById('write-message').ariaSelected = 'false';
            document.getElementById('write-message-page').classList.remove('active', 'show');

            // open write-message tab
            document.getElementById('write-message').classList.add('active');
            document.getElementById('write-message').ariaSelected = 'true';
            document.getElementById('write-message-page').classList.add('active', 'show');

            // open modal
            document.getElementById('messages-modal').style.transform = 'translateY(-20px)';
            document.getElementById('messages-modal').style.opacity = '0';
            document.getElementById('messages-modal').style.display = 'block';
            document.getElementById('messages-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('messages-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('messages-modal').style.transform = 'translateY(0)';
                document.getElementById('messages-modal').style.opacity = '1';
            }, 0);
        },
    },
};
</script>

<style scoped>
.city-tile-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
}

.city-image {
    position: relative;
    height: 100%;
    width: auto;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.city-clickable-overlay {
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    z-index: 1;
}

.city-clickable-overlay:hover + picture .city-image,
.city-image.highlighted {
    -webkit-filter: drop-shadow(1px 1px 0 palegoldenrod) drop-shadow(-1px -1px 0 lightgoldenrodyellow) brightness(1.1)
        contrast(1.1);
    filter: drop-shadow(1px 1px 0 palegoldenrod) drop-shadow(-1px -1px 0 lightgoldenrodyellow) brightness(1.1) contrast(1.1);
}
</style>
