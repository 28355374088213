export const BuildingIdentifier = Object.freeze({
    MAIN_BUILDING: 'Main Building',
    BARRACKS: 'Barracks',
    GENERAL_OFFICE: 'General Office',
    ALUMINUM_SMELTER: 'Aluminum Smelter',
    METAL_MINING_CAMP: 'Metal Mining Camp',
    OIL_EXTRACTOR: 'Oil Extractor',
    ARMORED_FACTORY: 'Armored Factory',
    PLANE_FACTORY: 'Plane Factory',
    AIRPORT: 'Airport',
    STORAGE: 'Storage',
    COMMERCIAL_ZONE: 'Commercial Zone',
    FARM: 'Farm',
    FORTIFICATIONS: 'Fortifications',
});

export const CityLevels = Object.freeze([
    [0, 500],
    [501, 1500],
    [1501, 2500],
    [2501, 9999999999],
]);

export const CityColorLabels = Object.freeze({
    CURRENT_CITY: 'Yellow',
    OWNED_CITY: 'Light Yellow',
    FRIEND: 'Green',
    COALITION_MEMBER: 'Blue',
    ALLY: 'Light Blue',
    NAP: 'Purple',
    ENEMY: 'Red',
    OTHER: 'White',
    BARBARIAN: 'Dark Grey'
})

export const CityColors = Object.freeze({
    'Yellow': 'gold',
    'Light Yellow': 'palegoldenrod',
    'Green': 'darkseagreen',
    'Blue': 'royalblue',
    'Light Blue': 'lightskyblue',
    'Purple': 'mediumpurple',
    'Red': 'indianred',
    'White': 'ghostwhite',
    'Dark Grey': 'gray'
})