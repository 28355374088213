<template>
    <div
        class="map-tile-wrapper"
        :style="`
			left: ${MAP_TILE_WIDTH * tile.x}px;
			top: ${MAP_TILE_HEIGHT * tile.y}px;
			width: ${MAP_TILE_WIDTH}px;
			height: ${MAP_TILE_HEIGHT}px;
		`"
    >
<!--        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: yellow; z-index: 1000">-->
<!--            {{ tile.x }} | {{ tile.y }}-->
<!--        </span>-->
        <picture>
            <source media="(max-width: 799px)" :srcset="require(`../../assets/map_tiles/map_${tile.piece}_mobile.avif`)" />
            <source media="(min-width: 800px)" :srcset="require(`../../assets/map_tiles/map_${tile.piece}_desktop.avif`)" />
            <img :src="require(`../../assets/map_tiles/map_${tile.piece}_desktop.avif`)" class="map-tile" draggable="false" />
        </picture>
		<DecorationTile
			v-for="decoration in tile.decorations"
			:key="`${decoration.x}${decoration.y}`"
			:decoration="decoration"
		/>
		<CityTile
			v-for="city in tile.cities"
			:key="city._id"
			:city="city"
			:is-being-centered-on="centeredOnCity === city._id"
			:map-scale-factor="mapScaleFactor"
			:current-city-id="currentCityId"
		/>
    </div>
</template>

<script>
import { MAP_TILE_WIDTH, MAP_TILE_HEIGHT } from '@/utils/constants/map';
import CityTile from '@/components/WorldMap/CityTile';
import DecorationTile from "@/components/WorldMap/DecorationTile";

export default {
    name: 'MapTile',
    components: { DecorationTile, CityTile },
    props: {
        tile: {
            type: Object,
            default: () => {},
        },
        centeredOnCity: {
            type: String,
            default: '',
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
        currentCityId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            MAP_TILE_WIDTH,
            MAP_TILE_HEIGHT,
        };
    },
};
</script>

<style scoped>
.map-tile-wrapper {
    position: absolute;
    opacity: 1;
}

.map-tile {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    width: 100%;
    height: 100%;
    /*outline: 1px solid gray;*/
}
</style>
