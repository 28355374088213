<template>
    <Transition name="fade">
        <div
            v-if="isTooltipVisible"
            class="city-hover-info-wrapper"
            :style="`
				transform: scale(${1 / mapScaleFactor});
				left: ${60 / mapScaleFactor}%;
				top: ${60 / mapScaleFactor}%;
			`"
        >
            <div class="hover-info-group">
                <img src="../../assets/icons/city_icon.svg" class="hover-info-icon" />
                <div class="hover-info-text">
                    <span class="hover-info-title"> City </span>
                    <div class="hover-info-details">
                        <span class="hover-info-name">Miserupia (554|231)</span>
                        <span class="hover-info-points">12.775</span>
                    </div>
                </div>
            </div>
            <div class="hover-info-group">
                <img src="../../assets/icons/population_icon.svg" class="hover-info-icon" />
                <div class="hover-info-text">
                    <span class="hover-info-title"> Player </span>
                    <div class="hover-info-details">
                        <span class="hover-info-name">Costy-Fortza</span>
                        <span class="hover-info-points">903.398</span>
                    </div>
                </div>
            </div>
            <div class="hover-info-group">
                <img src="../../assets/icons/alliance_icon.svg" class="hover-info-icon" />
                <div class="hover-info-text">
                    <span class="hover-info-title"> Coalition </span>
                    <div class="hover-info-details">
                        <span class="hover-info-name">Cartofi (Crt)</span>
                        <span class="hover-info-points">15.902.370</span>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'CityTooltip',
	props: {
		isTooltipVisible: {
			type: Boolean,
			default: false,
		},
		mapScaleFactor: {
			type: Number,
			default: 1,
		},
	},
};
</script>

<style scoped>
.city-hover-info-wrapper {
	z-index: 361;
	position: absolute;
	background: rgba(0, 0, 0, 0.8);
	border: 1px solid darkgray;
	width: fit-content;
	padding: 0.5rem;
	opacity: 1;
	pointer-events: none;
	transition: filter 0.15s linear, opacity 0.15s linear;
}

.hover-info-group {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	min-width: max-content;
	padding: 0 0.25rem;
}

.hover-info-group:not(:last-child) {
	margin-bottom: 0.25rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid darkgray;
}

.hover-info-icon {
	width: 1.625rem;
	height: 1.625rem;
}

.hover-info-text {
	display: flex;
	flex-direction: column;
}

.hover-info-title {
	font-size: 0.875rem;
	line-height: 0.875rem;
	color: ghostwhite;
}

.hover-info-details {
	display: flex;
	gap: 1rem;
	font-size: 0.75rem;
	line-height: 1rem;
	color: ghostwhite;
}

.hover-info-name {
	color: darkgray;
	position: relative;
}

.hover-info-name::after {
	content: '.';
	font-weight: 800;
	color: lightgray;
	position: absolute;
	right: -0.6875rem;
	top: -25%;
}

.hover-info-points {
	color: burlywood;
}

.fade-enter-from,
.fade-leave-to {
	filter: blur(3px);
	opacity: 0;
}
</style>
