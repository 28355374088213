<template>
    <div class="minimap-large-wrapper">
        <div
            v-dragscroll
            ref="minimapWrapper"
            class="minimap-wrapper"
            @scroll="throttle(emitScrollCoords, throttleTimerCoordsEmit, 16)"
            @touchstart="stopCoordsEmit"
            @mousedown="stopCoordsEmit"
        >
            <canvas ref="minimapCanvas" class="minimap" />
        </div>
        <div class="minimap-center-square" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    PIXEL_RATIO,
    MINIMAP_HORIZONTAL_SCALE,
    MINIMAP_VERTICAL_SCALE,
    MINIMAP_WIDTH,
    MINIMAP_HEIGHT,
    MINIMAP_POINT_WIDTH,
    MINIMAP_POINT_HEIGHT,
    CITY_TILES_HORIZONTALLY,
    CITY_TILES_VERTICALLY,
} from '@/utils/constants/map';
import { CityColors } from '@/utils/constants/city';

export default {
    name: 'Minimap',
    props: {
        worldMapScrollCoords: {
            type: Object,
            default: () => {},
        },
        windowSize: {
            type: Object,
            default: () => {},
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
        currentCityId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            PIXEL_RATIO,
            MINIMAP_HORIZONTAL_SCALE,
            MINIMAP_VERTICAL_SCALE,
            MINIMAP_WIDTH,
            MINIMAP_HEIGHT,
            MINIMAP_POINT_WIDTH,
            MINIMAP_POINT_HEIGHT,
            CITY_TILES_HORIZONTALLY,
            CITY_TILES_VERTICALLY,
            CityColors,

            minimapWrapper: null,
            canvas: null,
            ctx: null,
            cityCoords: null,
            coordsEmitStopped: false,
            // throttle timers are objects so that they can be passed by reference
            // to throttle function, rather than by value
            throttleTimerCoordsEmit: {
                timer: null,
            },
        };
    },
    computed: {
        minimapCenterSquareWidth() {
            return (this.windowSize?.width * MINIMAP_HORIZONTAL_SCALE) / this.mapScaleFactor;
        },
        minimapCenterSquareHeight() {
            return (this.windowSize?.height * MINIMAP_VERTICAL_SCALE) / this.mapScaleFactor;
        },
        minimapPaddingX() {
            return (this.minimapWrapperWidth - this.minimapCenterSquareWidth) / 2;
        },
        minimapPaddingTop() {
            return (this.minimapWrapperHeight - this.minimapCenterSquareHeight) / 2;
        },
        minimapPaddingBottom() {
            return (this.minimapWrapperHeight - this.minimapCenterSquareHeight) / 2 - 6;
        },
        mapMatrix() {
            return this.getMapMatrix();
        },
        minimapHeightOffset() {
            return this.windowSize?.height > 700 ? 570 : 390;
        },
        minimapWrapperWidth() {
            return Math.max(this.windowSize?.width / 4, 330);
        },
        minimapWrapperHeight() {
            return Math.min(this.windowSize?.height - this.minimapHeightOffset, 700);
        },
		newlyReceivedCity() {
			return this.getNewlyReceivedCity();
		}
    },
    watch: {
        mapMatrix: {
            handler(newVal) {
                const isMapMatrixPopulated = newVal.length > 0;

                if (isMapMatrixPopulated) {
					console.log('draw minimap');
                    this.drawMinimap();
                }
            },
        },
		newlyReceivedCity: {
			handler(newVal) {
				if (newVal) {
					console.log('new cityyyyyy !!!!', newVal);
					this.drawCity(newVal);
				}
			},
		},
        mapScaleFactor: {
            handler() {
                // stop view from sliding when zooming out on map border
                this.stopCoordsEmit();
                this.allowCoordsEmit();
                this.drawMinimap();
            },
        },
        worldMapScrollCoords: {
            handler() {
                if (!this.coordsEmitStopped) {
                    if (this.minimapWrapper !== undefined) {
                        this.minimapWrapper.scrollLeft = this.worldMapScrollCoords.x * MINIMAP_HORIZONTAL_SCALE;
                        this.minimapWrapper.scrollTop = this.worldMapScrollCoords.y * MINIMAP_VERTICAL_SCALE;
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapGetters(['getMapMatrix', 'getNewlyReceivedCity']),
        throttle(func, timer, delay) {
            if (timer.timer === null) {
                func();
                timer.timer = setTimeout(() => {
                    timer.timer = null;
                }, delay);
            }
        },
        drawMinimap() {
            this.initializeMinimap();
            this.drawDecorations();
            this.drawGrid();
            this.drawCities();
        },
        initializeMinimap() {
            this.minimapWrapper = this.$refs.minimapWrapper;
            this.canvas = this.$refs.minimapCanvas;
            this.canvas.width = MINIMAP_WIDTH * PIXEL_RATIO;
            this.canvas.height = MINIMAP_HEIGHT * PIXEL_RATIO;
            this.ctx = this.canvas.getContext('2d');
            this.ctx.scale(PIXEL_RATIO, PIXEL_RATIO);
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            console.log(this.canvas.width, this.canvas.height, MINIMAP_POINT_WIDTH, MINIMAP_POINT_HEIGHT);
        },
        drawGrid() {
            for (let i = 0; i <= CITY_TILES_HORIZONTALLY; i++) {
                const startX = MINIMAP_POINT_WIDTH * i;
                const startY = 0;
                const endX = MINIMAP_POINT_WIDTH * i;
                const endY = MINIMAP_HEIGHT;

                this.ctx.beginPath();
                this.ctx.moveTo(startX, startY);
                this.ctx.lineTo(endX, endY);
                this.ctx.closePath();

                this.ctx.strokeStyle = '#5d4c36';
                this.ctx.lineWidth = 1;
                this.ctx.stroke();
            }
            for (let i = 0; i <= CITY_TILES_HORIZONTALLY; i++) {
                const startX = 0;
                const startY = MINIMAP_POINT_HEIGHT * i;
                const endX = MINIMAP_WIDTH;
                const endY = MINIMAP_POINT_HEIGHT * i;

                this.ctx.beginPath();
                this.ctx.moveTo(startX, startY);
                this.ctx.lineTo(endX, endY);
                this.ctx.closePath();

                this.ctx.strokeStyle = '#5d4c36';
                this.ctx.lineWidth = 1;
                this.ctx.stroke();
            }
            for (let i = 0; i <= CITY_TILES_HORIZONTALLY; i += 8) {
                const startX = MINIMAP_POINT_WIDTH * i;
                const startY = 0;
                const endX = MINIMAP_POINT_WIDTH * i;
                const endY = MINIMAP_HEIGHT;

                this.ctx.beginPath();
                this.ctx.moveTo(startX, startY);
                this.ctx.lineTo(endX, endY);
                this.ctx.closePath();

                this.ctx.strokeStyle = 'black';
                this.ctx.lineWidth = 1;
                this.ctx.stroke();
            }
            for (let i = 0; i <= CITY_TILES_HORIZONTALLY; i += 8) {
                const startX = 0;
                const startY = MINIMAP_POINT_HEIGHT * i;
                const endX = MINIMAP_WIDTH;
                const endY = MINIMAP_POINT_HEIGHT * i;

                this.ctx.beginPath();
                this.ctx.moveTo(startX, startY);
                this.ctx.lineTo(endX, endY);
                this.ctx.closePath();

                this.ctx.strokeStyle = 'black';
                this.ctx.lineWidth = 1;
                this.ctx.stroke();
            }
        },
        drawCities() {
            this.mapMatrix.forEach(row => {
                row.forEach(tile => {
                    tile.cities.forEach(city => {
                        this.drawCity(city);
                    });
                });
            });
        },
		drawCity(city) {
			this.ctx.fillStyle = this.getCityMinimapColor(city);

			this.ctx.fillRect(
				city.x * MINIMAP_POINT_WIDTH,
				city.y * MINIMAP_POINT_HEIGHT,
				MINIMAP_POINT_WIDTH,
				MINIMAP_POINT_HEIGHT,
			);
			this.ctx.strokeRect(
				city.x * MINIMAP_POINT_WIDTH,
				city.y * MINIMAP_POINT_HEIGHT,
				MINIMAP_POINT_WIDTH,
				MINIMAP_POINT_HEIGHT,
			);

			this.ctx.strokeStyle = 'black';
		},
        drawDecorations() {
            this.mapMatrix.forEach(row => {
                row.forEach(tile => {
                    tile.decorations.forEach(decoration => {
                        this.ctx.fillStyle = decoration.name === 'Forest' ? '#7c7f46' : '#927d6a';

                        this.ctx.fillRect(
                            decoration.x * MINIMAP_POINT_WIDTH,
                            decoration.y * MINIMAP_POINT_HEIGHT,
                            MINIMAP_POINT_WIDTH,
                            MINIMAP_POINT_HEIGHT,
                        );
                    });
                });
            });
        },
        stopCoordsEmit() {
            this.coordsEmitStopped = true;
            window.addEventListener('mouseup', this.allowCoordsEmit);
            window.addEventListener('touchend', this.allowCoordsEmit);
        },
        allowCoordsEmit() {
            setTimeout(() => {
                this.coordsEmitStopped = false;
                window.removeEventListener('mouseup', this.allowCoordsEmit);
                window.removeEventListener('touchend', this.allowCoordsEmit);
            }, 100);
        },
        getCityMinimapColor(city) {
            return CityColors[city.color];
        },
        emitScrollCoords() {
            this.$emit('minimap-scroll', {
                x: this.minimapWrapper.scrollLeft / MINIMAP_HORIZONTAL_SCALE,
                y: this.minimapWrapper.scrollTop / MINIMAP_VERTICAL_SCALE,
            });
        },
    },
};
</script>

<style scoped>
.minimap-large-wrapper {
    position: relative;
}

.minimap-wrapper {
    width: v-bind(minimapWrapperWidth + 'px');
    height: v-bind(minimapWrapperHeight + 'px');
    overflow: hidden;
    position: relative;
    /*outline: 2px solid #434343;*/
    background: #79664b;
    cursor: move;
}

.minimap {
    width: v-bind(MINIMAP_WIDTH + 'px');
    height: v-bind(MINIMAP_HEIGHT + 'px');
    margin: v-bind(minimapPaddingTop + 'px') v-bind(minimapPaddingX + 'px') v-bind(minimapPaddingBottom + 'px')
        v-bind(minimapPaddingX + 'px');
}

.minimap-center-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: v-bind(minimapCenterSquareWidth + 'px');
    height: v-bind(minimapCenterSquareHeight + 'px');
    outline: 1px solid black;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
}
</style>
