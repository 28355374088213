<template>
    <div class="row">
        <div class="col">
            <div class="troop-image-wrapper">
                <img
                    src="../../assets/icons/troops/truck-tall.avif"
                    alt="truck"
                    class="troop-image"
                    @click="openTroopDetailsModal(troop)"
                />
            </div>
        </div>
        <div class="col">
            <input v-model="ownedTrucksSelected" min="0" :max="trucks?.numOfTroops" class="form-control" />
            <span class="max-troops-to-send" @click="selectAllOwnedTrucks">({{ trucks?.numOfTroops.toLocaleString('ro') }})</span>
        </div>
        <div class="col controlled-reinforcements-select-column">
            <input v-if="controlledTrucksTemplate.length === 0" value="0" class="form-control" disabled />
            <input
                v-else-if="controlledTrucksTemplate.length === 1"
                v-model="controlledTrucksTemplate.length[0].toSend"
                class="form-control"
            />
            <input v-else :value="calculateTrucksSelectedFromDropdown()" class="form-control" disabled />
            <span class="max-troops-to-send" @click="selectAllControlledTrucks"
                >({{ trucks?.controlledReinforcements.toLocaleString('ro') }})</span
            >
            <button
                v-if="controlledTrucksTemplate.length > 1"
                class="btn neutral-button reinforcements-dropdown-button"
                @click="toggleReinforcementDropdownVisible"
            >
                <span>&#10095;</span>
            </button>
            <Transition name="tooltip-transition">
                <div v-if="reinforcementDropdownVisible" class="reinforcements-dropdown-wrapper">
                    <div class="row">
                        <div class="col label">From</div>
                        <div class="col label">City</div>
                        <div class="col label">Troops</div>
                    </div>
                    <div v-for="reinforcement in controlledTrucksTemplate" :key="reinforcement.id" class="row">
                        <div class="col">
                            <!--                                {{ getPlayerNameFromId(reinforcement.fromUser) }}-->
                            {{ reinforcement.fromUser?.username }}
                        </div>
                        <!-- TO DO: add city image before name -->
                        <div class="col">
                            <!--                                {{ getCityNameFromId(reinforcement.fromCity) }}-->
                            {{ reinforcement.fromCity?.name }}
                        </div>
                        <div class="col">
                            <div class="troop-image-wrapper">
                                <img
                                    src="../../assets/icons/troops/truck-tall.avif"
                                    alt=""
                                    class="troop-image"
                                    @click="openTroopDetailsModal(getTroopFromControlledReinforcements(reinforcement.troopName))"
                                />
                            </div>
                            <input v-model="reinforcement.toSend" min="0" :max="reinforcement.numOfTroops" class="form-control" />
                            <span class="max-troops-to-send" @click="selectAllControlledTrucksByIndex(reinforcement.index)"
                                >({{ reinforcement.numOfTroops.toLocaleString('ro') }})</span
                            >
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import { buildControlledReinforcementsTemplate } from '@/utils/functions/troopUtils';
import { TroopsIdentifier } from '@/utils/constants/troops';

export default {
    name: 'TruckSelect',
    props: {
        currentCityId: {
            type: String,
            default: '',
        },
        trucks: {
            type: Object,
            default: () => {},
        },
        reinforcements: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            TroopsIdentifier,

            ownedTrucksSelected: 0,
            controlledTrucksTemplate: this.buildControlledReinforcementsTemplate({
                reinforcements: this.reinforcements,
                troop_name: TroopsIdentifier.TRUCK,
                current_city_id: this.currentCityId,
            }),

            reinforcementDropdownVisible: false,
        };
    },
    methods: {
        buildControlledReinforcementsTemplate,

        selectAllOwnedTrucks() {
            this.ownedTrucksSelected = this.trucks.numOfTroops;
        },

        selectAllControlledTrucks() {
            this.controlledTrucksTemplate.forEach(reinforcement => {
                reinforcement.toSend = reinforcement.numOfTroops;
            });
        },

        selectAllControlledTrucksByIndex(index) {
            this.controlledTrucksTemplate[index].toSend = this.controlledTrucksTemplate[index].numOfTroops;
        },

        calculateTrucksSelectedFromDropdown() {
            return this.controlledTrucksTemplate.reduce((accumulator, currentValue) => {
                if (!currentValue.toSend) {
                    return accumulator;
                }
                return accumulator + parseInt(currentValue.toSend, 10);
            }, 0);
        },

        toggleReinforcementDropdownVisible() {
            this.reinforcementDropdownVisible = !this.reinforcementDropdownVisible;
        },
    },
};
</script>

<style scoped>
.row {
	flex-wrap: nowrap;
	gap: 2px;
	margin: 0;
	min-height: 2rem;
}

.label .col {
	background: linear-gradient(to bottom, #2a2a2a, #181818);
	color: ghostwhite;
	height: 3rem;
	display: flex;
	align-items: center;
	font-size: 1rem;
}

.col {
	position: relative;
	flex: 1 1 auto;
	background: rgba(0, 0, 0, 0.5);
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	gap: 0.5rem;
	display: flex;
	font-size: 0.875rem;
	align-items: center;
	min-width: 4.5rem;
}

.col.label {
	background: linear-gradient(to bottom, #2a2a2a, #181818);
}

.btn {
	padding: 0.25rem;
}

.neutral-button {
	font-family: 'bebas', sans-serif;
	border-radius: 0;
	background: linear-gradient(to bottom, rgb(30, 30, 30), rgba(255, 255, 255, 0.2));
	border: 3px solid dimgray;
	font-size: 0.9375rem;
	line-height: 0.9375rem;
	color: ghostwhite;
}

.neutral-button:hover {
	background: linear-gradient(to bottom, rgba(40, 40, 40, 0.2), rgb(60, 60, 60));
	color: ghostwhite;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.form-control {
	width: 100%;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
	border: 1px solid whitesmoke;
	border-radius: 0;
	padding-left: 1rem;
	opacity: 0.8;
	color: ghostwhite;
	height: 2rem;
}

.form-control:focus {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
	color: ghostwhite;
	border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
	-webkit-text-fill-color: ghostwhite;
	caret-color: ghostwhite;
}

.form-control:disabled {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
	color: ghostwhite;
	border: 1px solid ghostwhite;
	opacity: 0.25;
}

.max-troops-to-send {
	color: wheat;
	font-size: 0.75rem;
}

.max-troops-to-send:hover {
	color: cornflowerblue;
	cursor: pointer;
}

.controlled-reinforcements-select-column {
	position: relative;
}

.reinforcements-dropdown-button > span {
	writing-mode: vertical-lr;
}

.reinforcements-dropdown-wrapper {
	position: absolute;
	top: 3.5rem;
	right: -1rem;
	z-index: 1;
	display: flex;
	flex-direction: column;
	gap: 2px;
	background: black;
	padding: 2px;
	width: max-content;
}

.reinforcements-dropdown-wrapper .col:not(.label) {
	background: rgba(150, 150, 150, 0.125);
}

.reinforcements-dropdown-wrapper .col:last-child {
	min-width: 14rem;
}

.troop-image-wrapper {
	min-width: 2.5rem;
	height: 2.5rem;
	overflow: hidden;
}

.troop-image {
	width: 2.5rem;
	cursor: pointer;
}
</style>
