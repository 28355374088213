export default class CollectionItem {
	collectionPointers = new Set();

	addCollectionPointer(collection) {
		this.collectionPointers.add(collection);
	}

	deleteCollectionPointer(collection) {
		this.collectionPointers.delete(collection);
	}

	selfDestruct() {
		this.collectionPointers.forEach(collection => {
			collection.deleteItem(this);
		})
	}
}