<template>
    <MapArrowsPair
        v-for="arrows_pair in mapArrows"
        :key="`${arrows_pair.ownCity._id}${arrows_pair.targetCity._id}`"
        :arrows-pair="arrows_pair"
		@scroll-to-target="(targetId, targetCoords) => $emit('scroll-to-target', targetId, targetCoords)"
    />
</template>

<script>
import MapArrowsPair from '@/components/WorldMap/MapArrowsPair';
export default {
    name: 'MapArrowsLayer',
    components: { MapArrowsPair },
    props: {
        mapArrows: {
            type: Set,
            default: () => new Set(),
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
    },
	emits: ['scroll-to-target'],
};
</script>

<style scoped></style>
