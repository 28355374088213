<template>
    <div
        class="decoration-tile-wrapper"
        :style="`
			width: ${CITY_TILE_WIDTH}px;
			height: ${CITY_TILE_HEIGHT}px;
			left: ${CITY_TILE_WIDTH * decoration.localX}px;
			top: ${CITY_TILE_HEIGHT * decoration.localY}px;
		`"
    >
        <picture>
            <source media="(max-width: 799px)" :srcset="require(`../../assets/map_tiles/${imagePrefix}_mobile.avif`)" />
            <source media="(min-width: 800px)" :srcset="require(`../../assets/map_tiles/${imagePrefix}_desktop.avif`)" />
            <img
                class="decoration-image"
                :src="require(`../../assets/map_tiles/${imagePrefix}_desktop.avif`)"
                draggable="false"
            />
        </picture>
    </div>
</template>

<script>
import { CITY_TILE_WIDTH, CITY_TILE_HEIGHT } from '@/utils/constants/map';

export default {
    name: 'DecorationTile',
    props: {
        decoration: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        imagePrefix() {
            // TO DO: extract strings into enum
            return this.decoration.name === 'Forest'
                ? `trees_${this.decoration.piece + 1 > 5 ? 5 : this.decoration.piece + 1}`
                : `rock_${this.decoration.piece + 1 > 4 ? 4 : this.decoration.piece + 1}`;
        },
    },
    data() {
        return {
            CITY_TILE_WIDTH,
            CITY_TILE_HEIGHT,
        };
    },
};
</script>

<style scoped>
.decoration-tile-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
}

.decoration-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
</style>
