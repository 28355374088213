<template>
    <div class="indicator-select-panel">
        <div
            class="panel-indicator-wrapper"
            v-for="pin in pins"
            :key="pin.id"
            @mouseover="$emit('pin-hovered', pin.element)"
            @mouseleave="$emit('pin-unhovered', pin.element)"
			@click="$emit('pin-clicked', pin.id)"
        >
			<img
				src="../../assets/icons/map_pin_icon.svg"
				class="panel-indicator"
				:class="[ActionPinCssClass[pin.actionType]]"
			/>
            <span class="city-name-and-coords">
                {{ pin.targetName }}
                <span>( {{ pin.targetX }} | {{ pin.targetY }} )</span>
            </span>
        </div>
    </div>
</template>

<script>
import { ActionPinCssClass } from "@/utils/constants/actions";
import { mapGetters } from "vuex";

export default {
    name: 'PinSelectPanel',
	props: {
		pins: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			ActionPinCssClass,
		};
	},
	computed: {
		mapScaleFactor() {
			return this.getMapScaleFactor();
		}
	},
	emits: ['pin-clicked', 'pin-hovered', 'pin-unhovered'],
	methods: {
		...mapGetters(['getMapScaleFactor']),
	},
};
</script>

<style scoped>
.indicator-select-panel {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: max-content;
	height: auto;
	bottom: calc(125% / v-bind(mapScaleFactor));
	left: 50%;
	transform: translateX(-50%) scale(v-bind(1 / mapScaleFactor));
	background: rgba(0, 0, 0, 0.8);
	border: 1px solid darkgray;
	padding: 0.5rem;
}

.panel-indicator-wrapper {
	display: flex;
	gap: 0.75rem;
	cursor: pointer;
}

.panel-indicator {
	width: 1.5rem;
	height: 1.5rem;
}

.city-name-and-coords {
	display: flex;
	column-gap: 0.125rem;
	flex-wrap: wrap;
	font-size: 0.75rem;
	color: palegoldenrod;
}

.city-name-and-coords:before {
	content: 'Target: ';
	color: ghostwhite;
}
</style>
