<template>
    <div class="action-pin-wrapper">
        <img
            :id="currentPinId"
            :data-target-name="action.target.name"
            :data-target-x="action.target.x"
            :data-target-y="action.target.y"
            :data-action-type="action.actionType"
            src="../../assets/icons/map_pin_icon.svg"
            class="action-pin"
            :class="[ActionPinCssClass[action.actionType]]"
            @mouseover="highlightStackedPins"
            @mouseleave="unhighlightAllPins"
            @click="selectPinAndUpdateHighlightedPins(currentPinId, true)"
        />
        <Transition name="tooltip-transition">
            <PinSelectPanel
                v-if="isPinSelectPanelOpen"
                :pins="highlightedPins"
                @pin-hovered="pin_element => highlightPin(pin_element)"
                @pin-unhovered="pin_element => unhighlightPin(pin_element)"
                @pin-clicked="pin_id => selectPinAndUpdateHighlightedPins(pin_id, false)"
            />
        </Transition>
        <Transition name="tooltip-transition">
            <TroopsInfoPanel
                v-if="isTroopsInfoPanelOpen"
                :troops="action.troops.countedByTroop"
                :target="action.target"
                :is-intercept="isIntercept"
                @scroll-to-target="(targetId, targetCoords) => $emit('scroll-to-target', targetId, targetCoords)"
            />
        </Transition>
    </div>
    <div v-if="isIntercept" class="intercept-meeting-point">&#10006;</div>
</template>

<script>
import MapAction from '@/classes/mapActions/MapAction';
import { ActionPinCssClass } from '@/utils/constants/actions';
import TroopsInfoPanel from '@/components/WorldMap/TroopsInfoPanel';
import PinSelectPanel from '@/components/WorldMap/PinSelectPanel';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'MapActionPin',
    components: { PinSelectPanel, TroopsInfoPanel },
    props: {
        action: {
            type: MapAction,
            default: () => new MapAction(),
        },
        angleBetweenCities: {
            type: Number,
            default: 0,
        },
        isOutgoingAction: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        highlightedPins() {
            return this.getHighlightedPins();
        },
        currentPinId() {
            return this.action.originalData._id;
        },
        selectedActionPin() {
            return this.getSelectedActionPin();
        },
        isPinSelected() {
            return this.currentPinId === this.selectedActionPin;
        },
        isPinSelectPanelOpen() {
            return this.isPinSelected && this.highlightedPins.length > 1;
        },
        isTroopsInfoPanelOpen() {
            return this.isPinSelected && this.highlightedPins.length === 1;
        },
        isIntercept() {
            return this.action.isIntercept;
        },
        actionProgress() {
            return this.isOutgoingAction
                ? `${this.action.progressTracking.percentageDone}%`
                : `${100 - this.action.progressTracking.percentageDone}%`;
        },
        meetingPointPosition() {
            return `${this.action.originalData.interceptPoint}%`;
        },
        angle() {
            return this.angleBetweenCities < 0
                ? `${Math.abs(this.angleBetweenCities)}deg`
                : `-${Math.abs(this.angleBetweenCities)}deg`;
        },
    },
    data() {
        return {
            ActionPinCssClass,

            indicatorArea: 1600, // bounding box area of the action indicator icon, based on a width and height of 40px
            intersectionThreshold: 0.5, // threshold for determining if two indicators are stacked
        };
    },
    emits: ['scroll-to-target'],
    methods: {
        ...mapGetters(['getHighlightedPins', 'getSelectedActionPin']),
        ...mapMutations(['setHighlightedPins', 'setSelectedActionPin']),

        highlightStackedPins() {
            const PinElements = this.getAllPinElements();

            const CurrentPin = PinElements.find(pin_element => this.getPinElementId(pin_element) === this.currentPinId);
            this.highlightPin(CurrentPin);
            PinElements.forEach(other_pin => {
                if (this.getPinElementId(other_pin) === this.currentPinId) {
                    return;
                }

                if (this.arePinsStacked(this.getPinElementBoundingBox(CurrentPin), this.getPinElementBoundingBox(other_pin))) {
                    this.highlightPin(other_pin);
                }
            });
        },

        getHighlightedPinElements() {
            return document.getElementsByClassName('pin-highlighted');
        },

        getAllPinElements() {
            return Array.from(document.getElementsByClassName('action-pin'));
        },

        getPinElementBoundingBox(element) {
            return element.getBoundingClientRect();
        },

        getPinElementId(element) {
            return element.id;
        },

        unhighlightAllPins() {
            const PinElements = this.getAllPinElements();
            PinElements.forEach(pin_element => this.unhighlightPin(pin_element));
        },

        arePinsStacked(current_box, other_box) {
            const OverlapWidth = Math.min(current_box.right, other_box.right) - Math.max(current_box.left, other_box.left);
            const OverlapHeight = Math.min(current_box.bottom, other_box.bottom) - Math.max(current_box.top, other_box.top);
            const OverlapArea = Math.max(0, OverlapWidth) * Math.max(0, OverlapHeight);

            return OverlapArea >= this.intersectionThreshold * this.indicatorArea;
        },

        highlightPin(element) {
            if (!element.classList.contains('pin-highlighted')) {
                element.classList.add('pin-highlighted');
            }
        },

        unhighlightPin(element) {
            if (element.classList.contains('pin-highlighted')) {
                element.classList.remove('pin-highlighted');
            }
        },

        selectPinAndUpdateHighlightedPins(pin_id, reset_if_selected) {
            if (reset_if_selected) {
                this.performCheckAndSetSelectedPin(pin_id);
            } else {
                this.setSelectedPin(pin_id);
            }
            this.setHighlightedPins();
        },

        performCheckAndSetSelectedPin(pin_id) {
            if (this.selectedActionPin === pin_id) {
                this.resetSelectedPin();
            } else {
                this.setSelectedPin(pin_id);
            }
        },

        setSelectedPin(pin_id) {
            this.$store.commit('setSelectedActionPin', pin_id);
        },

        resetSelectedPin() {
            this.$store.commit('setSelectedActionPin', null);
        },

        setHighlightedPins() {
            this.$store.commit('setHighlightedPins', this.mapHighlightedPins(this.getHighlightedPinElements()));
        },

        mapHighlightedPins(pin_elements) {
            return Array.from(pin_elements).map(pin_element => ({
                element: pin_element,
                id: this.getPinElementId(pin_element),
                targetName: pin_element.dataset.targetName,
                targetX: pin_element.dataset.targetX,
                targetY: pin_element.dataset.targetY,
                actionType: pin_element.dataset.actionType,
            }));
        },
    },
};
</script>

<style scoped>
.action-pin-wrapper {
    z-index: 9;
    position: absolute;
    bottom: 0;
    left: v-bind(actionProgress);
    transform-origin: bottom;
    transform: translate(-50%, -12%) rotateZ(v-bind(angle));
    transition: all 1s linear;
}

.action-pin {
    width: 40px;
    height: 40px;
    opacity: 0.75;
    cursor: pointer;
}

.action-pin.pin-highlighted {
    opacity: 1;
}

.intercept-meeting-point {
    color: brown;
    font-size: 2rem;
    position: absolute;
    transform: translateX(-50%);
    left: v-bind(meetingPointPosition);
}

.indicator-red {
    filter: drop-shadow(-1px -1px 1px black);
    -webkit-filter: drop-shadow(-1px -1px 1px black);
}

.indicator-brown {
    filter: brightness(0.75) saturate(0.75) drop-shadow(-1px -1px 1px black);
    -webkit-filter: brightness(0.75) saturate(0.75) drop-shadow(-1px -1px 1px black);
}

.indicator-purple {
    filter: hue-rotate(290deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(290deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
}

.indicator-blue {
    filter: hue-rotate(200deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(200deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
}

.indicator-lightblue {
    filter: hue-rotate(200deg) brightness(2.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(200deg) brightness(2.25) drop-shadow(-1px -1px 1px black);
}

.indicator-green {
    filter: hue-rotate(130deg) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(130deg) drop-shadow(-1px -1px 1px black);
}

.indicator-dark-green {
    filter: hue-rotate(130deg) brightness(0.75) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(130deg) brightness(0.75) drop-shadow(-1px -1px 1px black);
}

.indicator-orange {
    filter: hue-rotate(50deg) brightness(3) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(50deg) brightness(3) drop-shadow(-1px -1px 1px black);
}

.indicator-yellow {
    filter: hue-rotate(70deg) brightness(5) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(70deg) brightness(5) drop-shadow(-1px -1px 1px black);
}

.indicator-white {
    filter: grayscale(1) brightness(10) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(10) drop-shadow(-1px -1px 1px black);
}

.indicator-black {
    filter: grayscale(1) brightness(0) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(0) drop-shadow(-1px -1px 1px black);
}

.indicator-gray {
    filter: grayscale(1) brightness(0.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(0.25) drop-shadow(-1px -1px 1px black);
}

.tooltip-transition-enter-active,
.tooltip-transition-leave-active {
    transition: filter 0.05s linear;
}

.tooltip-transition-enter-from,
.tooltip-transition-leave-to {
    filter: blur(3px);
}
</style>
